/**
 * # Contact Box
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Styles
 */
.wrapper {
    margin-top: $spacing * 20;
    text-align: center;
}

.box {
    background: rgba($color: #518AE91A, $alpha: 0.1);
    border-radius: $border-radius-xl;
    padding: ($spacing * 4) ($spacing * 8);
    padding-bottom: 32px;

    @include minSm {
        padding-bottom: $spacing * 12;
        padding-top: $spacing * 12;
    }
}

.headline {
    @extend %headline-primary-sonic;
    margin-bottom: $spacing * 12;

    @include minSm {
        margin-bottom: $spacing * 16;
    }
}

.copytext {
    font-size: 22px;
    line-height: 33px;
    text-wrap: balance;
}

.space-bottom {
    margin-bottom: $spacing * 12 !important;
}