/**
 * # Testimonials
 */
/**
 * ## Imports
 */
 @import 'src/common/styles/variables.scss';
 @import 'src/common/styles/utilities.scss';

 /**
 * Styles
 */

 .wrapper {
    margin-top: 80px;
    margin-bottom: 80px;

    @include minSmMd {
        margin-top: 100px;
        margin-top: 80px;
    }

    &.adblock {
        margin-top: 60px;
        margin-bottom: 60px;

        @include maxSm {
            padding-left: 16px;
            padding-right: 16px;
        }

        @include minSmMd {
            margin-top: 120px;
            margin-bottom: 120px;
        }
    }
 }

 .headline {
    font-family: $sonicFontFamilySemiBold;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    color: $sonic-gray-1000;
    margin: 0;

    @include maxSm {
        max-width: 285px;
        text-wrap: pretty;
        margin-left: auto;
        margin-right: auto;
    }

    &--adblock {
        font-family: $adblockFontFamily;
        font-size: 48px;
        line-height: 69.07px;
        font-weight: 400;
        color: $black;

        @include maxSm {
            font-size: 24px;
            line-height: 34.54px;
            text-align: left;
            font-weight: 700;
            max-width: unset;

            & .tiny {
                font-weight: 400;
            }
        }

        & strong {
            font-weight: 700;
        }
    }
 }

 .reviews-wrapper {
    position: relative;
    display: flex;
    overflow: hidden;
    user-select: none;
    margin-top: 42px;

    @include maxSm {
        .adblock & {
            margin-top: 24px;
        }
    }

    @include minSmMd {
        margin-top: 64px;
    }

    &::before,
    &::after {
        content: "";
        height: 100%;
        top: 0;
        width: 10%;
        position: absolute;
        z-index: 1;
        pointer-events: none;
    }

    &::before {
        left: 0;
    }

    &::after {
        right: 0;
    }
 }

 .reviews {
    flex-shrink: 0;
    display: flex;
    justify-content: space-around;
    width: 120vw;
    min-width: 100%;
    user-select: none;
    animation: scroll 20s linear infinite;
    counter-reset: item;
    position: relative;

    .adblock & {
        width: 200vw;

        @include minLg {
            width: 120vw;
        }

        @include minSmMaxSmMd {
            width: 250vw;
        }
    }

    @include minSmMdMaxMd {
        width: 150vw;
    }

    @include maxSm {
        .adblock & {
            animation: none;
            transition: transform 600ms ease-in-out;
            width: auto;
            gap: 10px;

            &.review-0 {
                transform: translateX(0);
            }

            &.review-1 {
                transform: translateX(-20%);
            }

            &.review-2 {
                transform: translateX(-40%);
            }

            &.review-3 {
                transform: translateX(-60%);
            }
            &.review-4 {
                transform: translateX(-80%);
            }
        }
    }

    @include maxSmMd {
        width: auto;
    }
 }

 .nav {
    position: absolute;
    bottom: 0;
    cursor: pointer;
    border-radius: 100%;

    @include minSm {
        display: none;
    }

    &--right {
        right: 0;
    }

    &--left {
        right: 70px;
        transform: rotate(180deg);
    }

    &--disabled {
        cursor: default;
        pointer-events: none;
        &>path:last-child {
            stroke: #ABB0C9;
        }
    }
 }

 .review {
    display: inline-block;
    animation: marquee 15s linear infinite;
    max-width: 350px;
    min-width: 350px;
    width: 100%;
    white-space: unset;
    vertical-align: top;
    padding-left: 24px;
    padding-right: 24px;
    counter-increment: item;

    @include maxSm {
        .adblock & {
            max-width: 92vw;
            min-width: unset;
            padding-left: 0;
            padding-right: 0;
        }
    }
    

    @include minSmMd {
        max-width: 534px;
        padding-left: 48px;
        padding-right: 48px;
    }

    &--text {
        max-width: 302px;
        font-family: $sonicFontFamily;
        font-size: 16px;
        line-height: 28px;
        font-weight: 400;
        color: $tundora;
        white-space: normal;
        margin-top: 0;
        margin-bottom: 32px;

        @include minSmMd {
            max-width: 413px;
            font-size: 20px;
            line-height: 30px;
        }

        .adblock & {
            font-family: $adblockFontFamily;
            color: $adblock-text;

            @include maxSm {
                line-height: 23.02px;
                max-width: calc(100vw - 15px);
                white-space: unset;
                text-wrap: pretty;
                margin-bottom: 24px;
            }
        }
    }

    &--author {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 12px;
    }
 }

//  .adblock {
//     & :global(.star-icon) {
//         & path {
//             fill: $adblock-star;
//         }
//     }
//  }

 .hide-mobile {
    @include maxSm {
        display: none;
    }
 }

 @keyframes scroll {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }