/**
 * # Device Teaser
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Styles
 */

/**
 * ### Illustration Section
 */
.illustration-section {
    position: relative;
    z-index: 2;

    &__centerwrapper {
        @extend %centerwrapper;

        @include minSm {
            display: flex;
            gap: $spacing * 6;
        }
    }

    &__text-body {
        @include minSm {
            max-width: 40%;
            padding-top: 10px;
        }

        @include minMd {
            padding-top: 140px;
        }
    }

    &__headline {
        @extend %headline-exception-sonic-secondary;
    }

    &__subline {
        @extend %headline-exception-sonic-quaternary;
        opacity: .7;
    }
}

.illustration-wrapper {
    @include minSm {
        align-self: flex-end;
        max-width: 50%;
    }
}

.illustration {
    margin: -8% 0 -18%;
    max-width: 100%;

    @include minSm {
        margin: 0 -100px -24.3% 0;
        max-width: unset;
        width: 156%;
    }
}

/**
 * ### OS Section
 */
.os-section {
    background-color: $marketing-blue-black-300;
    border-radius: $border-radius-xxl;
    color: $white;
    margin-bottom: $spacing * 12;
    padding: ($spacing * 8) 0 ($spacing * 12);
    position: relative;
    z-index: 1;

    @include minSm {
        margin-bottom: $spacing * 20;
        padding: ($spacing * 20) 0 ($spacing * 12);
    }

    &__centerwrapper {
        @extend %centerwrapper;

        @include minSm {
            display: flex;
            gap: $spacing * 6;
            justify-content: space-between;
        }
    }

    &__text-body {
        @include minSm {
            width: 50%;
        }

        @include minMd {
            width: 30%;
        }
    }

    &__headline {
        @extend %headline-exception-sonic-secondary;
    }

    &__subline {
        @extend %headline-exception-sonic-quaternary;
        margin-bottom: $spacing * 7;
    }
}

.os-list {
    @include minSm {
        display: flex;
        flex-wrap: wrap;
        gap: $spacing * 8;
        width: 50%;
    }

    &__item {
        margin-bottom: $spacing * 8;

        @include minSm {
            margin-bottom: 0;
            width: calc(48% - ($spacing * 4));
        }

        &--inactive {
            opacity: 0.5;
        }
    }

    &__headline {
        @extend %headline-exception-sonic-quintary;
        margin: 0 0 ($spacing * 2);
    }

    &__copytext {
        color: rgba($white, .7);
        margin-top: 0;
    }

    &__icon {
        height: 28px;
        width: auto;

        @include minSm {
            height: 32px;
        }
    }
}

.centered-content {
    justify-content: center;

    & img {
        @include minSmMd {
            margin-left: -120px;
            margin-bottom: -90px;
        }

        @include maxSmMd {
            margin-left: -75px;
        }

        @include maxSm {
            margin-left: 0;
        }

    }
}

.button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 70px;
    margin-bottom: 70px;
}

.continue-button {
    button {
        width: 270px;
        height: 80px;
        font-size: 20px;
        border-radius: 20px;
    }
}