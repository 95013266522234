/**
 * # Feature Teaser
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Styles
 */
.teaser {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing * 4;
    padding: 0;

    @include darkMode {
        background-color: $marketing-blue-black-500;
    }

    @include minSmMd {
        flex-direction: row;
        gap: $spacing * 8;
        justify-content: space-between;
    }

    &-item {
        @include maxSmMd {
            margin-bottom: 34px;
        }
    }
}

.textbox {
    order: 2;

    @include minSmMd {
        align-items: flex-start;
        display: flex;
        flex: 1;
        flex-direction: column;
        flex-shrink: 1;
        flex-wrap: wrap;
        padding-top: 128px;
        text-align: left;
        max-width: 532px;
        padding-left: 20px;
        padding-right: 20px;
    }

    @include maxSmMd {
        max-width: 350px;
        text-align: left;
        margin: 0 auto;
    }

    @include minMd {
        padding-top: $spacing * 20;
    }

    .teaser-item:nth-of-type(2n + 1) & {
        @include minSmMd {
            order: 2;
        }
    }

    .teaser-item:nth-of-type(2n + 2) & {
        @include minSmMd {
            order: 1;
        }
    }
}

.headline {
    font-family: $sonicFontFamilySemiBold;
    font-size: 20px;
    line-height: 20px;
    font-weight: 600;
    color: $sonic-gray-1000;
    margin: 0;
    margin-bottom: 16px;

    @include minSmMd {
        font-size: 32px;
        line-height: 32px;
    }
}

.paragraph {
    font-family: $sonicFontFamily;
    font-size: 16px;
    line-height: 27px;
    font-weight: 400;
    color: rgba($color: $sonic-gray-1000, $alpha: 0.7);
    margin: 0;

    @include minSmMd {
        max-width: 492px;
    }

}

.imagebox {
    align-items: center;
    background: rgba($color: #518AE91A, $alpha: 0.1);
    border-radius: 40px;
    display: flex;
    flex-grow: 0;
    flex-shrink: 1;
    justify-content: center;
    max-width: 100%;
    order: 1;
    overflow: hidden;
    height: 328px;
    width: 342px;
    padding: 35px 52px;

    @include maxSmMd {
        margin-bottom: $spacing * 6;
        @include center-x;
    }

    @include minMd {
        height: 490px;
        width: 580px;
    }

    .teaser-item:nth-of-type(2n + 1) & {
        @include minSmMd {
            order: 1;
        }
    }

    .teaser-item:nth-of-type(2n + 2) & {
        @include minSmMd {
            order: 2;
        }
    }

    .teaser-item:nth-child(2) & {
        background: rgba($color: #F9E5301A, $alpha: 0.1);
        justify-content: flex-end;
        padding-right: 0;
    }

    .teaser-item:nth-child(3) & {
        background: rgba($color: #4C22C31A, $alpha: 0.1);

    }

    .teaser-item:nth-child(4) & {
        background: rgba($color: #EF25381A, $alpha: 0.1);
    }
}

.image {
    max-width: 100%;

    @include minSm {
        height: auto;
        max-width: 100%;
    }

    .teaser-item:nth-child(1) & {
        height: auto;
    }

    .teaser-item:nth-child(2) & {
        height: 100%;
    }

    .teaser-item:nth-child(3) & {
        max-width: 81%;
        height: auto;
    }

    .teaser-item:nth-child(4) & {
        max-width: 50%;
        height: auto;
    }
}

.button-cta {
    min-width: 174px;
    margin-top: 32px;

    @include maxSmMd {
        display: none;

        &__active {
            display: block;
            width: 100%;
        }
    }
}