/**
 * # Tempest Map
 */
/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
  * 🚨 Editors beware! This component was copied from the repo `tempest-search`.
  * Any breaking changes need to be kept in sync!
  */
.tempest-map {
    margin: 0 0 ($spacing * 10);
    width: 100%;

    &__body {
        height: 100%;
        position: relative;
        width: 100%;
    }

    &__image {
        position: relative;
        width: 100%;
        z-index: 1;
    }

    &__pins {
        height: 100%;
        position: absolute;
        width: 100%;
        z-index: 2;
    }

    &__pin {
        border-radius: 100%;
        height: $spacing * 3;
        left: 0;
        overflow: hidden;
        opacity: 1;
        position: absolute;
        text-indent: -999em;
        transform-origin: center;
        transition: opacity .5s ease-in-out, transform .5s ease-in-out;
        top: 0;
        width: $spacing * 3;

        /* 🇺🇸 */
        &--us-wa {
            left: 5.5%;
            top: 30%;
            transform: scale(1.33333);
        }

        /* 🇺🇸 */
        &--us-nv {
            left: 7%;
            top: 34%;
        }

        /* 🇺🇸 */
        &--us-ny {
            left: 18%;
            top: 29%;
        }

        /* 🇲🇽 */
        &--mx {
            left: 6%;
            top: 40%;
        }

        /* 🇬🇧 */
        &--uk {
            left: 39.8%;
            top: 16%;
            transform: scale(1.16667);
        }

        /* 🇮🇪 */
        &--ie {
            left: 38%;
            top: 16%;
            transform: scale(1.33333);
        }

        /* 🇫🇷 / 🇩🇪 */
        &--fr-de {
            left: 40.9%;
            top: 21%;
            transform: scale(1.66667);
        }

        /* 🇩🇪 */
        &--de {
            left: 42.4%;
            top: 19%;
            transform: scale(1.83333);
        }

        /* 🇵🇹 */
        &--pt {
            left: 37%;
            top: 28%;
        }

        /* 🇫🇷 */
        &--re {
            left: 58%;
            top: 70%;
            transform: scale(0.75);
        }

        /* 🇹🇭 */
        &--th {
            left: 72%;
            top: 51%;
        }

        /* 🇸🇬 */
        &--sg {
            left: 74%;
            top: 55%;
        }

        &--active {
            transform: scale(1.8);
        }

        &--invisible {
            opacity: 0;
        }
    }

    &__legend {
        position: relative;
        z-index: 3;
    }

    &__legend-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: ($spacing * 5) 0 ($spacing * 23);

        // @include maxSm {
        //     display: none;
        // }

        @include minSm {
            margin-top: 0;
        }
    }

    &__legend-item {
        &:nth-of-type(n+2) {
            margin-left: 1px;
        }
    }

    &__button {
        background-color: transparent;
        border: none;
        border-radius: $border-radius-xxl;
        color: $light-black;
        cursor: pointer;
        font-family: "Poppins", Helvetica, Arial, sans-serif;
        font-size: 14px;
        min-width: $spacing * 16;
        padding: ($spacing * 2) ($spacing * 4) ($spacing * 2) ($spacing * 8);
        position: relative;
        transition: background-color .25s ease-in;

        @include darkMode {
            color: $white;
        }

        &:hover,
        &--active {
            background-color: rgba($white, .1);
        }

        &--all {
            padding-left: ($spacing * 4);
        }

        &::before {
            border-radius: 100%;
            content: "";
            display: block;
            height: 10px;
            left: 13px;
            position: absolute;
            top: 13px;
            width: 10px;
        }
    }

    /**
       * ## Engineering 🛠️
       */
    .tempest-map__button--engineering::before {
        background-color: $marketing-purple-300;
    }

    .tempest-map__pin--engineering {
        background-color: $marketing-purple-300;
        /* - `outline` isn't working on Safari 16.1 OSX. Therefor `box-shadow`: */
        box-shadow: 0 0 0 12px rgba($marketing-purple-300, .1);
    }

    /**
       * ## Product 📦
       */
    .tempest-map__button--product::before {
        background-color: $marketing-turquoise-100;
    }

    .tempest-map__pin--product {
        background-color: $marketing-turquoise-100;
        box-shadow: 0 0 0 12px rgba($marketing-turquoise-100, .1);
    }

    /**
       * ## Marketing 📺
       */
    .tempest-map__button--marketing::before {
        background-color: $marketing-pink-100;
    }

    .tempest-map__pin--marketing {
        background-color: $marketing-pink-100;
        box-shadow: 0 0 0 12px rgba($marketing-pink-100, .1);
    }

    /**
       * ## Design 🖌️
       */
    .tempest-map__button--design::before {
        background-color: $marketing-green-100;
    }

    .tempest-map__pin--design {
        background-color: $marketing-green-100;
        box-shadow: 0 0 0 12px rgba($marketing-green-100, .1);
    }

    /**
       * Finance 💸
       */
    .tempest-map__button--finance::before {
        background-color: $marketing-blue-450;
    }

    .tempest-map__pin--finance {
        background-color: $marketing-blue-450;
        box-shadow: 0 0 0 12px rgba($marketing-blue-450, .1);
    }

    /**
       * ## Operations 🔬
       */
    .tempest-map__button--operations::before {
        background-color: $marketing-purple-50;
    }

    .tempest-map__pin--operations {
        background-color: $marketing-purple-50;
        box-shadow: 0 0 0 12px rgba($marketing-purple-50, .1);
    }
}