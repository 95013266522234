/**
 * # Company Logo Component
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Common Classes
 */
.logo-icon-tempest {
    height: 86px;
    width: 86px;

    @include minSm {
        height: 122px;
        width: 122px;
    }

    :global(.pagefooter) & {
        height: auto;
    }
}

.logo-icon-adblock {
    width: 156px;
    height: 36px;

    @include minSm {
        width: 286px;
        height: 72.97px;
    }

    // @include minSmMaxSmMd {
    //     width: 183px;
    //     height: 36px;
    // }

    // @include minSmMdMaxMd {
    //     width: 183px;
    //     height: 36px;
    // }
}

.logo-icon-sonic {
    height: auto;
    width: 120px;

    @include minMd {
        height: 75px;
        width: 197px;
    }

    :global(.pagefooter) & {
        background: url("../../images/sonic_browser/icons/logo-icon.svg") 0 center no-repeat;
        background-size: 148px auto;
        height: 100%;
        width: 100%;

        @include darkMode {
            /** - The Figma design currently isn't offering a better source: **/
            background-image: unset;
            -webkit-mask-position: center;
            mask-position: center;
            -webkit-mask-repeat: no-repeat;
            mask-repeat: no-repeat;
            -webkit-mask-size: 148px auto;
            mask-size: 148px auto;
            -webkit-mask-image: url("../../images/sonic_browser/icons/logo-icon-monochrome.svg");
            mask-image: url("../../images/sonic_browser/icons/logo-icon-monochrome.svg");

            background-color: $white;
        }

        @include minSm {
            height: 75px;
            width: 100%;
        }
    }
}

:global(.logo-svg) {
    :global(.path-typography) {
        fill: $white;
    }
}

.company-logo-wrapper {
    line-height: 1;

    :global(.cta-teaser) & {
        opacity: 0;
        @include cssAnimation(fadeIn, .25s, 1s);

        @include minSmMd {
            margin: 0 0 ($spacing * 9) ($spacing * -3);
        }
    }

    :global(.pagefooter) & {
        align-items: flex-end;
        display: flex;
        grid-row-start: 2;
        width: 148px;

        @include minSmMd {
            grid-row-start: 1;
        }

        &.company-logo-wrapper--sonic {
            @include maxSmMd {
                align-self: center;
                grid-column: 1 / span 2;
                grid-row-start: 1;
                height: 58px;
                justify-self: flex-start;
            }
        }

        &.company-logo-wrapper--adblock {
            @include maxSmMd {
                width: 142.73px;
            }
        }
    }

    &__link {
        display: block;
        height: 100%;
        max-width: 100%;
        width: 100%;

        .company-logo-wrapper--adblock & {
            @include maxSmMd {
                max-width: 142.73px;
                & svg {
                    width: 100%;
                }
            }
        }
    }
}