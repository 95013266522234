/**
 * # Smarter Browsing feature component
 */
/**
 * ## Imports
 */
 @import 'src/common/styles/variables.scss';
 @import 'src/common/styles/utilities.scss';

 /**
 * Styles
 */
 .wrapper {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;

    @include maxMd {
      padding-left: 16px;
      padding-right: 16px;
    }
 }

 .title {
    font-family: $adblockFontFamily;
    font-size: 24px;
    line-height: 34.54px;
    font-weight: 700;
    text-align: center;
    max-width: 936px;
    margin: 0 auto;
    text-wrap: pretty;

    @include minSmMd {
      font-size: 48px;
      line-height: 69.07px;
    }
 }

 .carousel-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 660.25px;
    margin-top: 64px;

    @include minSmMaxSmMd {
      margin-top: 24px;
    }

    @include maxSmMd {
      flex-direction: column;
      margin-top: 24px;
      height: max-content;
    }
  }
  
  .image-container {
    position: relative;
    width: 51%;
    height: inherit;

    @include maxSmMd {
      width: 100%;
      padding-bottom: calc(100% + 27px);
      min-height: 385px;
      margin-bottom: 48px;
    }

    @include minSmMaxSmMd {
      padding-bottom: 75%;
    }
  }
  
  .carousel-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 1s ease-in-out;

    @include maxSmMd {
      width: 100%;
      height: auto;
      min-height: 385px;
    }

    @include minSmMaxSmMd {
      width: 70%;
      left: 50%;
      transform: translateX(-50%);
    }

    @include minSmMdMaxMd {
      height: auto;
    }
  }
  
  .carousel-image.active {
    opacity: 1;
  }
  
  .accordion-container {
    width: 36%;
    cursor: pointer;
    position: relative;

    @include maxSmMd {
      width: 100%;
    }

    @include minSmMaxSmMd {
      width: 70%;
    }
  }
  
  .accordion-item {
    margin-bottom: 20px;
    transition: max-height 1s ease-in-out;
    font-family: $adblockFontFamily;
    margin-bottom: 24px;

    &--title {
        font-size: 24px;
        line-height: 34.54px;
        font-weight: 700;
        color: $black;
        margin: 0;
        opacity: 60%;

        @include minSmMd {
          font-size: 32px;
          line-height: 46.05px;
        }
    }

    &--text {
        font-size: 16px;
        line-height: 27px;
        font-weight: 400;
        color: $adblock-text;
        opacity: 70%;
        margin: 0;
        padding-top: 0;
        height: 0;
        overflow: hidden;
        transition: all 600ms ease-in-out;
    }
  }
  
  .accordion-item.active {
    & .accordion-item--title {
        opacity: 1;
    }

    & .accordion-item--text {
        height: 70px;
        padding-top: 16px;
    }
  }
  
  .vertical-line {
    position: absolute;
    left: -40px;
    top: 0;
    bottom: 120px;
    width: 4px;
    background-color: #DEE3EE;
    border-radius: 4px;
    overflow: hidden;

    @include maxSmMd {
      left: 0;
      right: 0;
      top: -24px;
      bottom: unset;
      width: 100%;
      height: 4px;
    }
  }
  
  .progress {
    width: 100%;
    background-color: #00DBFF; /* or any color you want */
    transition: all 1s ease-in-out;
    border-radius: 4px;

    @include maxSmMd {
      height: 4px;
    }


    &--0 {
        height: 30.87px;

        @include maxSmMd {
          width: 30.87px;
          height: 4px;
        }
    }

    &--1 {
        height: 134.5px;

        @include maxSmMd {
          width: 134.5px;
          height: 4px;
        }
    }

    &--2 {
        height: 262.74px;

        @include maxSmMd {
          width: 262.74px;
          height: 4px;
        }
    }
  }

  .button {
    margin-top: 42px;
    margin-left: -40px;

    @include maxSmMd {
      margin-top: 0;
      margin-left: 0;
      padding: 0 16px;
      height: 60px;
      min-width: 178px;
    }
  }