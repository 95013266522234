/**
 * # Button CTA Rebrand
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

 /**
  * ## Styles
  */
.button-cta-wrapper {
    display: inline-block;
    margin-bottom: $spacing * 3;
    position: relative;

    /* Creating a border via pseudo element: */
    &::after {
        animation: animatedgradient 3s ease infinite;
        background: linear-gradient(60deg, #e13e8d, #ef4e7b, #a166ab, #5341c5, #3d2f99);
        background-size: 300% 300%;
        border-radius: calc(66.1538px + 2px);
        content: "";
        height: calc(100% + 2px);
        left: -1px;
        position: absolute;
        top: -1px;
        width: calc(100% + 2px);
        z-index: 0;
    }

    .cta-teaser & {
        margin: ($spacing * 3) 0 ($spacing * 8);
        position: relative;
        z-index: 2;
        opacity: 0;
        @include cssAnimation(fadeIn, .75s, 1s);
    }
}

.button-cta {
    @include button-cta;

    &--chrome-extension {
        background-image: linear-gradient(51.14deg,#6081f5 -11.73%,#5341c5 57.09%,#3d2f99 102.08%),linear-gradient(60deg,#e13e8d,#ef4e7b,#a166ab,#5341c5,#3d2f99);

        @include maxMd {
            display: none;
        }
    }

    .button-cta-wrapper & {
        margin-bottom: 0;
        position: relative;
        z-index: 2;
    }

    &.button-cta--disabled {
        cursor: not-allowed;

        &.button-cta--sonic {
            opacity: .5;
        }
    }

    &--alt {
        background: linear-gradient(51.14deg, #6081F5 -11.73%, #5341C5 57.09%, #3D2F99 102.08%);
    }

    &--alt,
    &--lofty {
        &.button-cta--disabled {
            background: $marketing-turquoise-200;
        }
    }

    /**
     * ### Sonic Client
     */
    &--sonic {
        background: $sonic-blue-1000;
        border: none;
        border-radius: 50px;
        padding: 15px 40px;
        font-size: 19.85px;
        line-height: 41.96px;
        height: unset;
        font-family: $sonicFontFamily;
        font-weight: 600;
        margin-bottom: 0;

        &::before {
            box-shadow: none;
        }

        &:visited {
            background: $sonic-blue-1000;
        }

        &:hover,
        &:focus,
        &:active {
            background: $sonic-blue-1000;
        }

        :global(.pageheader) & {
            margin-bottom: 0;
            padding: 12px 25px;
        }
    }

    &--adblock {
        background: $adblock-button;
        font-family: $adblockFontFamily;
        font-size: 18px;
        line-height: 40.69px;
        font-weight: 700;
        margin-bottom: 0;
        color: $white;
        border-radius: 50px;
        padding: 0 38.8px;

        &:hover,
        &:focus,
        &:active,
        &:visited {
            background: $adblock-button;
        }

        :global(.pageheader) & {
            padding: 0 25px;
        }
    }

    &--adblock &__body {
        padding: 0;
        margin: 0;
    }

    &--download &__body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        & .download-icon {
            margin-right: 16px;
        }
    }

   &--sonic &__body {
        padding: 0;
    }
}

.button-cta__body {
    pointer-events: none;
    .button-cta--alt & {
        /** - Quite inconsistent but designed this way..: */
        font-size: 20px;
    }
}

/**
 * ### Animations
 */
@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}