/**
 * ## Global Teaser
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Styles
 */
.text-body {
    margin: 0 auto ($spacing * 10);
    text-align: center;

    @include minSm {
        margin-bottom: $spacing * 20;
    }

    @include minSmMd {
        max-width: 60%;
    }
}

.headline {
    @extend %headline-tertiary-sonic;
    text-wrap: balance;
}

.copytext {
    text-wrap: balance;
}

.divider {
    width: 100%;
    height: 1px;
    background-color: rgba($marketing-blue-gray-900, .2);
}