/**
 * # Base styles - styles that are valid accross all clients.
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Typography
 */
@font-face {
    font-family: "Poppins";
    src: url("./common/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins Bold";
    src: url("./common/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
    font-style: bold;
    font-display: swap;
}

@font-face {
    font-family: "Poppins Semi Bold";
    src: url("./common/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
    font-style: bold;
    font-display: swap;
}

@font-face {
    font-family: "Poppins Light";
    src: url("./common/fonts/Poppins/Poppins-Light.ttf") format("truetype");
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Poppins Thin";
    src: url("./common/fonts/Poppins/Poppins-Thin.ttf") format("truetype");
    font-style: normal;
    font-display: swap;
}

/**
* ## Objective Mk2 font
*/
@font-face {
    font-family: "Objective Mk2";
    src: url("./common/fonts/Objective_Mk2/ObjectiveMk2.ttf") format("truetype");
    font-style: normal;
    font-display: swap;
}

/**
  * ## HTML Elements
  */
html {
    background-color: #0c0b19;
    scroll-behavior: smooth;
}

html,
body {
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    /* - A `height: 100%;` declaration on the `html` element
     * leads to a non-scrollable webpage: */
    // height: 100%;
    margin: 0;
    width: 100%;
    -webkit-font-smoothing: antialiased;
}

body {
    height: 100%;
}

*,
:after,
:before {
    box-sizing: border-box;
}

/**
 * ### Scroll Bars
 */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background: $white;

    @include darkMode {
        background: transparent;
    }
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: $platinum-800;

    @include darkMode {
        background: $gray-800;
    }
}

/**
  * ### Typographic Elements
  */
ul,
ol {
    list-style: "";
    margin: 0;
    padding-left: 0;
}

abbr {
    text-decoration: none;
}

strong {
    font-family: "Poppins Semi Bold", Helvetica, Arial, sans-serif;
}

/**
  * ## Basic Elements
  */
#root {
    height: 100%;
}

/**
  * ## Center Wrappers
  */
.centerwrapper {
    @extend %centerwrapper;

    &--narrow {
        max-width: 1030px;
    }

    &--very-narrow {
        max-width: 600px;
    }
}

