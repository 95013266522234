/**
 * # Accordion
 */

/**
 * ## Imports
 */
 @import 'src/common/styles/variables.scss';
 @import 'src/common/styles/utilities.scss';

  /**
  * ## Styles
  */
  .accordion {
    display: block;
    margin-bottom: $spacing * 10;

    &-title {
        font-size: 24px;
        text-align: left;
        text-transform: uppercase;
        font-family: 'Poppins Light', Arial, Helvetica, sans-serif;
        margin-bottom: $spacing * 2;
    }

    &__summary {
        color: $black;
        cursor: pointer;
        padding: 18px;
        padding-left: 0;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
        font-size: 18px;
        line-height: 21.6px;
        font-weight: 700;
        background: transparent;
        border-bottom: 3px solid rgba(205, 214, 218, 0.25);
        transition: 300ms;
        font-family: 'Poppins SemiBold', Arial, Helvetica, sans-serif;

        &:first-child {
          border-top: none;
        }

        &::after {
            content: '\002B';
            color: inherit;
            font-weight: 400;
            float: right;
            margin-left: 5px;
            transition: all .2s ease-in-out;
          }

          &:global(.active) {
            &::after {
                transform: rotate(45deg);
            }
          }
    }

    &__content {
        font-family: $sonicFontFamily;
        font-size: 18px;
        line-height: 28.8px;
        color: rgba(#3C3C43, .85);
        padding: 0;
        max-height: 0;
        overflow: hidden;
        transition: all 0.2s ease-out;
        background-color: transparent;
        text-align: left;

        &:global(.active) {
          border-bottom: 3px solid rgba(205, 214, 218, 0.25);
        }

        &>p {
          margin: 0;
        }

        // @include darkMode() {
        //   color: $white;

        //   & a {
        //     color: $white;
        //   }
        // }
    }
  }

  .hidden-content {
    max-height: 0;
    overflow: hidden;
    transition: all 0.6s ease-in-out;

    &__visible {
      max-height: max-content;
    }
  }

  .seeAll-button {
    font-family: $sonicFontFamilySemiBold;
    font-size: 19.85px;
    line-height: 41.96px;
    font-weight: 600;
    text-align: center;
    color: $black;
    border: 1px solid $black;
    border-radius: 48px;
    background-color: transparent;
    padding: 0 40px;
    height: 72px;
    margin-top: 64px;
    cursor: pointer;
  }

  .adblock {
    & .accordion__summary {
      font-size: 18px;
      line-height: 21.6px;
      font-weight: 700;
    }

    & .accordion__content {
      font-family: $adblockFontFamily;
      font-size: 18px;
      line-height: 28.8px;
      font-weight: 400;
      color: $adblock-text;
    }

    & .seeAll-button {
      font-family: $adblockFontFamily;

      @include maxSm {
        font-size: 18px;
        line-height: 25.9px;
        height: 60px;
        margin-top: 24px;
      }
    }
  }