/**
 * # CTA Teaser Intro
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Styles
 */
.cta-teaser-intro {
    position: relative;
    z-index: 2;

    @include minSmMd {
        flex-shrink: 2;
        max-width: $spacing * 129;
        min-height: 660px;
        padding-top: $spacing * 18;
        padding-bottom: $spacing * 10;
        width: 50%;
    }

    &--tempest {
        text-align: center;

        @include minSmMd {
            text-align: left;
        }
    }

    &--sonic {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 0;
        @include cssAnimation(fadeIn, .25s, 3s);

        @include maxSmMd {
            padding-bottom: 52px;
        }

        @include minSm {
            max-width: unset;
            min-height: unset;
            padding-top: 118px;
            text-align: center;
            @include center-x;
        }

        @include minSmMd {
            width: 80%;
            padding-bottom: $spacing * 20;
        }
    }

    &--adblock {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        opacity: 0;
        max-width: 1190px;
        width: 100%;
        @include cssAnimation(fadeIn, .25s, 3s);
        padding-top: 0;
        padding-bottom: 0;
        margin: 0 auto;
        min-height: unset;
        justify-content: flex-start;
        margin-bottom: 80px;

        @include maxSm {
            margin-bottom: 40px;
        }
    }

    &--download-features {
        max-width: 55%;

        @include maxSm {
            max-width: 100%;
        }
    }
}

.headline-main {
    .cta-teaser-intro--tempest & {
        @extend %headline-main-tempest;
    }

    .cta-teaser-intro--sonic & {
        @extend %headline-primary-sonic;
        text-align: center;

        @include maxSm {
            line-height: 46px;
            text-wrap: pretty;
        }
    }

    .cta-teaser-intro--adblock & {
        font-family: $adblockFontFamily;
        font-size: 28px;
        line-height: 40px;
        text-align: center;
        font-weight: 400;
        margin: 0;

        @include minSm {
            font-size: 72px;
            line-height: 76px;
        }

        & strong {
            font-weight: 700;
        }
    }
}

:global(.intro-paragraph) {
    line-height: 34px;
    font-family: 'Poppins Light', Helvetica, Arial;
    font-size: 18px;
    margin-bottom: $spacing * 10;
    text-align: center;
    text-wrap: balance;

    .cta-teaser-intro--sonic & {
        font-size: 16px;
        line-height: 26px;
        margin: $spacing 0 ($spacing * 4);

        & a {
            @include darkMode {
                color: $white;
            }
        }

        @include minSm {
            max-width: 876px;
        }

        @include minSmMd {
            font-size: 22px;
            line-height: 34px;
            margin: ($spacing * 4) 0 ($spacing * 4);
        }

        :global(.intro-paragraph--bold) {
            font-size: 22px;
            line-height: 34px;
            margin-bottom: $spacing * 10;

            @include darkMode {
                color: $white;
            }
        }

        :global(.intro-paragraph__link) {
            color: $sonic-blue-gray-900;

            @include darkMode {
                color: $white;
            }
        }
    }

    .cta-teaser-intro--adblock & {
        font-family: $adblockFontFamily;
        font-size: 16px;
        line-height: 23.02px;
        font-weight: 400;
        color: $adblock-text;
        margin-top: 16px;
        margin-bottom: 36px;

        @include minSm {
            font-size: 20.34px;
            line-height: 32.98px;
            margin-top: 32px;
            margin-bottom: 46px;
        }

        @include maxSm {
            text-wrap: pretty;
        }
    }
}

:global(.intro-paragraph-download) {
    .cta-teaser-intro--sonic & {
        font-size: 18px;

        @include minSmMd {
            font-size: 18px;
        }
    }
}

.button-wrapper {
    .cta-teaser-intro--sonic & {
        width: 100%;
        @include minSmMd {
            margin-top: $spacing * 5;
        }
    }
}

.continue-button {
    .cta-teaser-intro--sonic & button {
        width: 427px;
        height: 128px;
        font-size: 40px;
        border-radius: 40px;

        @include maxSm {
            width: 260px;
            height: 80px;
            font-size: 24px;
            border-radius: 20px;
        }

        & span {
            justify-content: center;
        }

        & img {
            width: 34px;
            height: auto;

            @include maxSm {
                width: 24px;
            }
        }
    }
}