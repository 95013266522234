/**
* # Utilities.
* Common classes, placeholders and mixins for reusage to avoid code repetition.
*/

/**
* ## Imports
*/
@import 'src/common/styles/variables.scss';

/**
* ## Sass placeholders
*/
%no-underline {
    text-decoration-line: none;
}

/**
 * Use sr-only to hide an element visually without hiding it from
 * screen readers.
 */
%sr-only:not(:focus):not(:active) {
    border-width: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

%not-sr-only {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    padding: 0;
    position: static;
    white-space: normal;
    width: auto;
}

/**
* %link is properties for the common link's colors
*/

%link {
    @include themify($themes) {
        color: themed('link');
    }

    &:visited {
        @include themify($themes) {
            color: themed('linkVisited');
        }
    }
}

/**
* ## Sass mixins
*/

/**
 * @mixin center-x
 * @mixin center-y
 * @mixin center-xy
 */
@mixin center-x($position: relative) {
    position: $position;
    left: 50%;
    transform: translateX(-50%);
}

@mixin center-y($position: relative) {
    position: $position;
    top: 50%;
    transform: translateY(-50%);
}

@mixin center-xy($position: relative) {
    position: $position;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}

/**
* Truncate text strings.
* @param {number} $lineCount.
*/
@mixin truncate($lineCount: 2) {
    /**
    * These styles truncate the text string on
    * a specified line.
    */
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: $lineCount;
    -webkit-box-orient: vertical;
    /**
    * Necessary to keep the line-clamp working
    * inside a flex layout as well.
    */
    flex-grow: 0;
}

/**
 * ### Common Animations
 */
@mixin cssAnimation($animationName,
    $animationDelay: 0,
    $animationDuration: 1s,
) {
    animation: $animationName;
    animation-delay: $animationDelay;
    animation-duration: $animationDuration;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes slideHalfRightToLeft {
    0% {
        opacity: 0;
        transform: translateX(5%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideHalfLeftToRight {
    0% {
        opacity: 0;
        transform: translateX(-5%);
    }

    100% {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 15%, 0);
    }

    100% {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }
}

/**
* ## Utility classes
*/
.no-underline {
    @extend %no-underline;
}

.figure {
    margin: 0;
}

.abbreviation {
    text-decoration-line: none;
}

.sr-only {
    @extend %sr-only;
}

.not-sr-only {
    @extend %not-sr-only
}

.uppercase {
    text-transform: uppercase;
}

/*
* ## Media queries / breakpoints
*/
@mixin maxXsm {

    /* 575px */
    @media (max-width: #{$screen-max-xsm}) {
        @content;
    }
}

@mixin minXsm {

    /* 576px */
    @media (min-width: #{$screen-min-xsm}) {
        @content;
    }
}

@mixin maxSm {

    /* 759px */
    @media (max-width: #{$screen-max-sm}) {
        @content;
    }
}

@mixin minSm {

    /* 760px */
    @media (min-width: #{$screen-min-sm}) {
        @content;
    }
}

@mixin minSmMaxSmMd {
    @media (min-width: #{$screen-min-sm}) and (max-width: #{$screen-max-sm-md}) {
        @content;
    }
}

@mixin maxSmMd {

    /* 1023px */
    @media (max-width: #{$screen-max-sm-md}) {
        @content;
    }
}

@mixin minSmMd {

    /* 1024px */
    @media (min-width: #{$screen-min-sm-md}) {
        @content;
    }
}

@mixin minSmMdMaxMd {
    @media (min-width: #{$screen-min-sm-md}) and (max-width: #{$screen-max-md}) {
        @content;
    }
}

@mixin maxMd {

    /* 1161px */
    @media (max-width: #{$screen-max-md}) {
        @content;
    }
}

@mixin minMd {

    /* 1162px */
    @media (min-width: #{$screen-min-md}) {
        @content;
    }
}

@mixin maxMdAside {

    /* 1220px */
    @media (max-width: #{$screen-max-md-aside}) {
        @content;
    }
}

@mixin minMdAside {

    /* 1221px */
    @media (min-width: #{$screen-min-md-aside}) {
        @content;
    }
}

@mixin maxLg {

    /* 1679px */
    @media (max-width: #{$screen-max-lg}) {
        @content;
    }
}

@mixin minLg {
    /* 1680px */
    @media (min-width: #{$screen-min-lg}) {
        @content;
    }
}

@mixin maxFullHd {
    /* 1920px */
    @media (max-width: #{$screen-full-hd}) {
        @content;
    }
}

@mixin maxFullHdHeight {
    /* 1080px */
    @media (max-height: #{$screen-full-hd-height}) {
        @content;
    }
}

@mixin maxHd {
    /* 1366px */
    @media (max-width: #{$screen-hd-laptop}) {
        @content;
    }
}

@mixin maxHdHeight {
    /* 800px */
    @media (max-height: #{$screen-hd-laptop-height}) {
        @content;
    }
}

@mixin darkMode {
    .app-tempest {
        @media (prefers-color-scheme: dark) {
            @content;
        }
    }

    .app-tempest .no-prefers-color-scheme--dark & {
        @content;
    }
}

/**
 * ## Centerwrapper
 */
%centerwrapper {
    margin: 0 auto;
    max-width: 1230px;
    padding-left: $centerwrapper-padding;
    padding-right: $centerwrapper-padding;
    width: 100%;

    @include minSm {
        /**
          * - This calculation is just a spontaneous impression
          * of good space distribution. No design provided: feel
          * free to change it later on:
          */
        width: calc(100% - $centerwrapper-padding-minSmMd);
    }

    @include minLg {
        width: calc(100% - $centerwrapper-padding-minSmMd * 2);
    }
}

/**
  * ## Typography
  */
%headline {
    color: $white;
    font-family: "Poppins Light", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 24px;
    margin: ($spacing * 2) 0;

    .cta-teaser__left & {
        margin-top: $spacing * 2;
        opacity: 0;
        @include cssAnimation(fadeIn, .5s, 1s);

        @include minSmMd {
            /**
             * - Fixing a weird layout error for Chrome
             * most likely caused by false inheritance
             * of `text-align: center;` of the parent element:
             */
            text-align: left;
        }
    }
}

%headline-main-tempest {
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-size: 36px;
    line-height: 47px;
    margin: ($spacing * 3) 0;

    @include minSm {
        font-size: 44px;
        line-height: 58px;
    }
}

%headline-sub-tempest {
    font-family: "Poppins Light", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 24px;

    @include minSm {
        font-size: 24px;
        line-height: 36px;
    }
}

%headline-primary-sonic {
    color: $sonic-gray-1000;
    font-family: "Poppins Semi Bold", Helvetica, Arial, sans-serif;
    font-size: 36px;
    line-height: 40px;
    font-weight: 600;
    margin: ($spacing * 3) 0;

    @include minSm {
        font-size: 72px;
        line-height: 67px;
        padding-right: 0;
    }

    @include darkMode {
        color: $white;
    }
}

%headline-secondary-sonic {
    color: $sonic-gray-1000;
    font-size: 32px;
    line-height: 42px;

    @include minSm {
        font-size: 44px;
        line-height: 58px;
    }

    @include darkMode {
        color: $white;
    }
}

%headline-tertiary-sonic {
    color: $sonic-gray-1000;
    font-size: 20px;
    line-height: 30px;
    margin-top: 0;

    @include minSm {
        font-size: 26px;
        line-height: 39px;
    }

    @include darkMode {
        color: $white;
    }
}

%headline-quaternary-sonic {
    color: $sonic-gray-1000;
    font-family: "Inter Semi Bold", Helvetica, Arial, sans-serif;
    font-size: 20px;
    line-height: 30px;
}

%homepage-link-sober {
    color: rgba($white, .5);
    font-size: 14px;
    text-decoration: none;
    transition: color ease-in .2s;

    &:hover {
      color: rgba($white, .8);
    }

    @include darkMode() {
        color: rgba($white, .5);
    }
}

%headline-exception-sonic {
    font-family: 'Poppins Semi Bold', Helvetica, Arial, sans-serif;
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 ($spacing * 2);

    @include darkMode {
        color: $white;
    }

    @include minSm {
        font-size: 44px;
        line-height: 58px;
    }
}

%headline-exception-sonic-secondary {
    font-family: 'Poppins Semi Bold', Helvetica, Arial, sans-serif;
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 6px;
    text-wrap: balance;

    @include darkMode {
        color: $white;
    }

    @include minSm {
        font-size: 44px;
        line-height: 58px;
        margin: 0 0 ($spacing * 3);
    }
}

%headline-exception-sonic-tertiary {
    font-family: "Poppins Semi Bold", Helvetica, Arial, sans-serif;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: $spacing * 2;

    @include darkMode {
        color: $white;
    }
}

%headline-exception-sonic-quaternary {
    font-size: 16px;
    line-height: 27px;
    margin-top: 0;
    opacity: .7;

    @include darkMode {
        color: $white;
    }

    @include minSm {
        font-size: 20px;
        line-height: 30px;
        margin: 0 0 ($spacing * 3);
        opacity: 1;
    }
}

%headline-exception-sonic-quintary {
    font-family: "Poppins Semi Bold", Helvetica, Arial, sans-serif;
    font-size: 22px;
    line-height: 34px;
}

%headline-exception-sonic-sextary {
    color: $sonic-gray-1000;
    font-family: "Poppins Light", Helvetica, Arial, sans-serif;
    font-size: 16px;
    line-height: 26px;
    text-wrap: balance;

    @include darkMode {
        color: $white;
    }

    @include minSm {
        font-size: 22px;
        line-height: 34px;
    }
}

%cta-paragraph {
    font-family: "Poppins", Helvetica, Arial, sans-serif;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: $spacing * 3;

    .advantages-screen & {
        opacity: 0;
        @include cssAnimation(fadeInUp, .4s, 1.25s);

        @include maxSmMd {
            margin-inline: auto;
            max-inline-size: 25ch;
            /**
              * - Not supported by Safari/Firefox yet.
              * Feel free to replace it with `padding`
              * if needed:
              */
            text-wrap: balance;
        }
    }
}

%description-label {
    font-size: 14px;
    line-height: 22px;
    margin: 0 0 ($spacing * 6);
    opacity: .7;
}

/**
* ## Button CTA Rebranded Version
* Default class for this button is `.button-cta`.
*/
@mixin interaction-arrow($background-color: rgba($black, .3),
    $background-image-1: "../images/homepage_rebrand/icons/arrow-right.svg",
    $background-image-2: "../images/homepage_rebrand/icons/arrow-right.svg",
    $background-position: (8px center, -16px center),
    $background-size: 14px 14px,
    $dimension: 30px,
    $background-position-hover: (32px center, 8px center),
) {
    &::after {
        background-color: $background-color;
        background-image:
            url($background-image-1),
            url($background-image-2);
        background-position: $background-position;
        background-size: $background-size;
        background-repeat: no-repeat;
        border-radius: 100%;
        content: "";
        display: block;
        height: $dimension;
        transition: background-position .2s ease-in-out;
        width: $dimension;
        animation-delay: 800ms;
    }

    &:hover::after {
        background-position: $background-position-hover;
    }
}

@mixin button-cta {
    // border: 1px solid $white;
    /* - Doesn't work with rounded corners.
    * border-image: linear-gradient(60deg, #E13E8DFF, #ef4e7b, #a166ab, #5341C5FF, #3D2F99FF);
    */
    border-image-slice: 1;
    border-radius: $spacing * 6;
    border: double 1px transparent;
    /* - Todo: align colors with designers and new color system. */
    // background-image: linear-gradient(51.14deg, #6081F5 -11.73%, #5341C5 57.09%, #3D2F99 102.08%),
    //   linear-gradient(60deg, #E13E8DFF, #ef4e7b, #a166ab, #5341C5FF, #3D2F99FF);
    background-image: linear-gradient(53.67deg, #7E64DC 12.55%, #A159EB 41.14%, #E959A6 68.32%, #F55196 87.77%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    color: $white;
    font-size: 12px;
    font-family: 'Poppins Semi Bold', sans-serif;
    height: 47px;
    line-height: 1;
    margin-bottom: $spacing * 3;
    padding: 0;
    position: relative;
    transition: all .25s ease-in;
    /* Please don't set a `z-index` on this element, or its
    * pseudo element can't display the optional glow behind it.
    */

    &:focus,
    &:active {
        background-image: linear-gradient(53.67deg, #7E64DC 12.55%, #A159EB 41.14%, #E959A6 68.32%, #F55196 87.77%);
    }

    &:hover {
        cursor: pointer;

        /* - This media query prevents touch devices from sticking with
         * the hover state after the button has been clicked: */
        @media (hover: hover) {
            background-image: linear-gradient(53.67deg, #7E64DC 12.55%, #A159EB 41.14%, #E959A6 68.32%, #F55196 87.77%);
        }
    }

    .button-cta__body {
        display: block;
        padding: ($spacing * 2) ($spacing * 4);
        position: relative;
        white-space: nowrap;
        z-index: 4;
    }

    /**
    * ### Button with Arrow Indicator
    */
    &.button-cta--arrow {
        @include interaction-arrow;

        &::after {
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
        }

        /**
        * SASS Mixins don't seem to operate correctly when the
        * `&` operator is used to reference to parent elements.
        * Therefor I unfortunately needed to create multiple
        * class instances of `.button-cta__body`.
        * Todo: evaluate if better notations are available.
        */
        .button-cta__body {
            display: flex;
            justify-content: center;
            padding-right: 70px;
        }
    }

    &.button-cta--animated-arrow {
        &::after {
            animation: pulse 1.5s infinite ease-out;
        }

        @keyframes pulse {
            0% {
                box-shadow: 0 0 0 0 rgba($black, .3);
            }

            20% {
                box-shadow: 0 0 0 5px rgba($black, .3);
            }

            30% {
                box-shadow: 0 0 0 0 rgba($black, .3);
            }

            100% {
                box-shadow: 0 0 0 0 rgba($black, .3);
            }
        }

        @-webkit-keyframes pulse {
            0% {
                box-shadow: 0 0 0 0 rgba($black, .3);
            }

            20% {
                box-shadow: 0 0 0 5px rgba($black, .3);
            }

            30% {
                box-shadow: 0 0 0 0 rgba($black, .3);
            }

            100% {
                box-shadow: 0 0 0 0 rgba($black, .3);
            }
        }
    }

    &.button-cta--arrow-large {
        @include interaction-arrow($background-color: rgba($black, .2),
            $background-position: (13px center, -30px center),
            $background-size: 23.42px 23.42px,
            $dimension: 48.79px,
            $background-position-hover: (60px center, 13px center),
        );

        &.button-cta--animated-arrow::after {
            animation: largePulse 1.5s infinite ease-out;
        }

        @keyframes largePulse {
            0% {
                box-shadow: 0 0 0 0 rgba($black, 0.2);
            }

            20% {
                box-shadow: 0 0 0 9px rgba($black, 0.2);
            }

            30% {
                box-shadow: 0 0 0 0 rgba($black, 0.2);
            }

            100% {
                box-shadow: 0 0 0 0 rgba($black, 0.2);
            }
        }

        @-webkit-keyframes largePulse {
            0% {
                box-shadow: 0 0 0 0 rgba($black, 0.2);
            }

            20% {
                box-shadow: 0 0 0 9px rgba($black, 0.2);
            }

            30% {
                box-shadow: 0 0 0 0 rgba($black, 0.2);
            }

            100% {
                box-shadow: 0 0 0 0 rgba($black, 0.2);
            }
        }
    }

    /**
    * ### Button with Big Layout
    */
    &--big {
        border: 0 solid transparent;
        border-radius: $spacing * 10;
        font-size: 16px;
        height: $spacing * 20;
        margin: ($spacing * 3) 0 ($spacing * 10);

        .button-cta__body {
            padding: ($spacing * 2) ($spacing * 8);
        }

        /**
        * ### Button with Big Layout and Arrow Indicator
        */
        &.button-cta--arrow {
            &::after {
                background-size: 24px 24px;
                background-position: 14px center,
                    -24px center;
                height: 50px;
                right: $spacing * 4;
                width: 50px;
            }

            &:hover::after {
                background-position: 62px center,
                    14px center;
            }

            .button-cta__body {
                align-items: center;
                border-radius: $spacing * 10;
                display: flex;
                height: 100%;
                padding-right: 82px;
                width: 100%;
            }
        }
    }

    /**
     * ### Button with Medium Layout
     */
    &--medium {
        height: 66px;
        padding-left: 0;

        &.button-cta--arrow {
            &::after {
                background-size: 24px 24px;
                background-position: 14px center,
                    -24px center;
                height: 50px;
                right: $spacing * 3;
                width: 50px;
            }

            &:hover::after {
                background-position: 62px center,
                    14px center;
            }

            .button-cta__body {
                font-size: 18px;
                padding-left: $spacing * 3;
            }
        }

    }

    /**
    * ### Button with Lofty Layout
    */
    &--lofty {
        background-color: $white;
        background-image: none;
        color: rgba($marketing-gray-900, .6);
        font-family: 'Poppins', Helvetica, Arial, sans-serif;

        &:hover {
            background-color: $platinum-500;
            background-image: none;
        }

        &:active {
            background-color: $marketing-blue-gray-200;
        }

        &.button-cta--arrow::after {
            background-image:
                url("../images/homepage_rebrand/icons/arrow-right-dark.svg"),
                url("../images/homepage_rebrand/icons/arrow-right-dark.svg");
            background-color: $marketing-blue-20;
        }

        &.button-cta--large {
            color: $thick-blue-600;
        }
    }

    /**
    * ### Button large
    */
    &--large {
        height: 78px;
        min-width: 227px;
        font-family: "Poppins Semi Bold", Helvetica, Arial, sans-serif;
        font-size: 20px;
        font-weight: 600;
        line-height: 26px;
        border-radius: 66.1538px;

        @include minSm {
            font-size: 24px;
            line-height: 36px;
        }
    }

    /**
    * ### Button with full width
    */
    &--full-width {
        width: 100%;
    }

    /**
    * ### Button with Reduced Layout
    */
    &--reduced {
        background-color: $marketing-blue-450;
        background-image: none;
        font-family: 'Poppins', sans-serif;

        &:hover {
            background-color: $marketing-blue-150;
            background-image: none;
        }

        &:active {
            background-color: $marketing-blue-110;
        }
    }

    /**
    * ### Button with Dry Layout
    */
    &--dry {
        /* - Todo: align colors with designers and new color system. */
        background: $marketing-blue-gray-900;
        color: rgba($white, .6);
        font-family: 'Poppins', sans-serif;

        &:hover {
            background: $marketing-blue-gray-800;
        }

        &:active {
            background: $marketing-blue-gray-700;
        }
    }

    /**
    * ### Button with Glow on the Bottom
    */
    &--glow-bottom::before {
        bottom: 0;
        /* - Todo: align colors with designers and new color system. */
        box-shadow: 0 -10px 41px 18px rgb(0 71 255 / 50%);
        content: "";
        height: 0;
        left: 0;
        width: 70%;
        z-index: 0;
        @include center-x(absolute);
    }
}

/**
 * ## Common App Container
 */
.app {
    color: $white;
    background-color: $marketing-blue-black-400;
    height: 100%;

    /**
     * ## Tempest Client
     */
    &--tempest {
        background-image:
        linear-gradient(
            0deg,
            rgba($black, 0.3),
            rgba($black, 0.3)
        ),
        radial-gradient(
            50% 50% at 50% 50%,
            $marketing-blue-gray-900 0%,
            #1D1D47 0.01%,
            $marketing-blue-gray-900 100%
        );
    }

    /**
     * ## Sonic Client
     */
    &--sonic {
        background-color: $sonic-gray-300;
        color: $sonic-blue-gray-900;
        overflow: hidden;
        position: relative;
        width: 100%;
        z-index: 1;

        &.app--home,
        &.app--support,
        &.app--welcome {
            background-color: $white;
        }

        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-between;
        min-height: 100vh;
        position: relative;

        @include maxFullHdHeight {
            zoom: 0.8;
        }

        @include maxHdHeight {
            zoom: 0.65;
        }

        @include darkMode {
            background-color: $marketing-blue-gray-900;
            color: rgba($white, .7);
        }

        /**
         * - The usage of decorative elements within the Figma design
         * is currently inconsistent. Hence individual styling for
         * each page: */
        // &.app--privacy,
        // &.app--customize,
        // &.app--features,
        // &.app--about {
        //     &::before {
        //         @include minSmMd {
        //             content: '';
        //             display: block;
        //             background: url(../images/sonic_browser/cta-teaser-ornament-a-big.svg) right top no-repeat;
        //             height: 460px;
        //             right: 0;
        //             position: absolute;
        //             top: 0;
        //             width: 300px;
        //             z-index: 3;
        //         }
        //     }
        // }
    }

    /**
    * ## Adblock Client
    */
    &--adblock {
        background-color: $white;
        color: $black;
        overflow: hidden;
        position: relative;
        width: 100%;
        z-index: 1;
    }
}

.framed-section {
    background-color: $white;
    border-radius: 0 0 $border-radius-xxxl $border-radius-xxxl;
    padding-bottom: $spacing * 3;
    margin-bottom: $spacing * 12;

    @include darkMode {
        background-color: $marketing-blue-black-500;
    }

    @include maxSm {
        padding-bottom: ($spacing * 10);
        padding-left: ($spacing * 5);
        padding-right: ($spacing * 5);
        text-align: center;

        .app--customize & {
            padding-bottom: 50px;
            margin-bottom: 0;
        }
    }

    @include minSm {
        padding-bottom: $spacing * 30;
        margin-bottom: $spacing * 40;

        .app--about &,
        .app--features &,
        .app--privacy & {
            padding-bottom: 0;
            margin-bottom: 80px;
        }

        .app--customize & {
            padding-bottom: 50px;
            margin-bottom: 0;
        }
    }

    &--less-margin-bottom {
        margin-bottom: $spacing * 10;
    }

    &--adblock {
        margin-bottom: 0;
        padding-bottom: 40px;
    }
}

.ios-section {
    padding-left: 0;
    padding-right: 0;
}

.privacy-teaser {
    @include maxSm {
        margin-bottom: $spacing * 8;
    }
}

/**
 * ## Typography
 */
.logo-typography {
    @include darkMode {
        fill: $white;
    }
}

.headline-main {
    .app--sonic &,
    .app--adblock & {
        @extend %headline-primary-sonic;
    }

    .app--adblock & {
        font-family: $adblockFontFamily;
        @include minSm {
            font-size: 38px;
        }
    }
}

.headline-secondary {
    .app--sonic & {
        @extend %headline-secondary-sonic;
    }
}

.headline-quaternary {
    .app--sonic & {
        @extend %headline-quaternary-sonic;
    }

    .app--sonic .copy-list--box & {
        @include darkMode() {
            color:  $sonic-gray-1000;
        }
    }
}

.headline-exception-secondary {
    .app--sonic & {
        @extend %headline-exception-sonic-secondary;
    }
}

.headline-exception-quintary {
    .app--sonic & {
        @extend %headline-exception-sonic-quintary;
    }
}

.headline-exception-sextary {
    .app--sonic & {
        @extend %headline-exception-sonic-sextary;
    }
}

/**
 * ### Copy Text. Sites which just contain a copy text block
 */
.copy-text {
    min-height: 100vh;

    .headline-exception-secondary {
        margin-top: $spacing * 8;

        @include minSm {
            margin-top: $spacing * 16;
        }
    }

    .copy-list > .headline-exception-secondary {
        margin-top: 0;
    }
}

.copy-list {
    list-style: disc;
    padding-left: $spacing * 4;

    .copy-list__item>& {
        margin-left: 30px;
    }

    &--ordered {
        list-style: none;
    }

    &--roman {
        list-style-type: none;
        counter-reset: roman-counter;
    }

    &--box {
        background-color: $white;
        border-radius: $border-radius-xxl;
        margin: ($spacing * 8) $spacing;
        padding: ($spacing * 4) ($spacing * 6);

        @include minSm {
            margin: ($spacing * 10) ($spacing * -15);
            padding: ($spacing * 10) ($spacing * 15);
        }

        &.copy-list,
        &.copy-list .copy-list__text {
            color: rgba($sonic-gray-1000, .7);
            font-size: 16px;
            line-height: 27px;
            @include darkMode() {
                color: $sonic-gray-1000;
            }
        }

        &.copy-list {
            color: $sonic-blue-gray-900;

            @include darkMode() {
                color: $sonic-gray-1000;
            }
        }
    }

    &__text {
        text-decoration: none;
    }

    &__item {
        margin-bottom: $spacing;

        ul.copy-list--ordered & {
            &::before {
                content: counter(list-item) ". ";
                display: inline;
                font-size: 20px;
                line-height: 30px;
                font-family: "Inter Semi Bold", Helvetica, Arial, sans-serif;
                font-weight: bold;
                float: left;
                margin-right: 5px;
                color: $sonic-gray-1000;
            }
        }

        ol.copy-list--ordered>& {
            list-style-type: decimal;
            &::marker {
                font-size: 16px;
                line-height: 25px;
                font-family: "Inter Semi Bold", Helvetica, Arial, sans-serif;
                font-weight: bold;
            }

            & ul>li>.copy-list__text {
                margin-bottom: 0;
                margin-top: 0;
            }
        }

        .copy-list--box & {
            margin-bottom: $spacing * 16;
        }

        .copy-list--roman & {
            counter-increment: roman-counter;
            margin-bottom: $spacing * 3;

            &::before {
                content: counter(roman-counter, upper-roman) ". ";
                margin-right: 0.5em; /* Optional: Adjust as needed */
            }
        }

        .copy-list--no-count>& {
            margin-bottom: 0;
            &::before {
                content: '';
                height: 100%;
            }
        }
    }
}

.underline {
    text-decoration: underline;
}

.policy-intro-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 27px;
    color: rgba($lightest-black, .7);

    @include darkMode() {
        color: $white;
    }
}

.italic {
    font-style: italic;
}