/**
 * # Customize Page
 */

/**
 * ## Imports
 */
 @import 'src/common/styles/variables.scss';
 @import 'src/common/styles/utilities.scss';
 
 /**
  * ## Styles
  */
.sonic-theme-slider {
    display: grid;
    place-items: center;
    z-index: 2;
    position: relative;
    margin-top: -47px;
    margin-bottom: 100px;
 
    @include maxSm {
       padding-left: 0;
       padding-right: 0;
       margin-top: -45px;
       margin-bottom: 40px;
    }
  }
 
  .slider-container {
    display: grid;
    place-content: center;
    position: relative;
    transition: 0.4s all ease-out;
    --position: 20%;
 
    & *, & *::after, & *::before {
       margin: 0;
       padding: 0;
       box-sizing: border-box;
    }
  }
 
  .image-container {
    max-width: 843px;
    max-height: 582px;
    height: auto;
    border-radius: 16px;
    overflow: hidden;
 
    @include maxSm {
       border-radius: 8px;
    }
  }
 
  .slider-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left;
    display: block;
    max-width: 100%;
  }
 
  .image-left {
    position: absolute;
    inset: 0;
    width: var(--position);
    height: calc(100% + 1px);
    border-top-left-radius: 16px;
    border-bottom-left-radius: 16px;
 
    @include maxSm {
       border-top-left-radius: 8px;
       border-bottom-left-radius: 8px;
    }
  }
 
  .slider {
    position: absolute;
    inset: 0;
    cursor: pointer;
    opacity: 0;
    /* for Firefox */
    width: 100%;
    height: 100%;
 
    &:focus-visible ~ &-button {
       outline: 5px solid black;
       outline-offset: 3px;
    }
 
    &-line {
       position: absolute;
       inset: 0;
       width: 1px;
       background-color: #929292;
       /* z-index: 10; */
       left: var(--position);
       transform: translateX(-50%);
       pointer-events: none;
       top: -26px;
       bottom: -26px;
 
       @include maxSm {
          top: -10px;
          bottom: -10px;
       }
     }
 
     &-button {
       position: absolute;
       color: black;
       padding: 20px;
       border-radius: 100vw;
       display: grid;
       place-items: center;
       top: 50%;
       left: var(--position);
       transform: translate(-50%, -50%);
       pointer-events: none;
 
       &-img {
          width: 40px;
       }
     }
  }