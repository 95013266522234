/**
 * # Button Burger
 */
/**
 * ## Imports
 */
 @import 'src/common/styles/variables.scss';
 @import 'src/common/styles/utilities.scss';

/**
  * ## Placeholders
  */
%pseudo-element {
    background-color: $sonic-gray-1000;
    content: "";
    display: block;
    height: $spacing;
    position: absolute;
    transition: transform .3s ease-in;
    width: $spacing;
    border-radius: $spacing;

    @include darkMode() {
        background-color: $white;
    }
}

/**
  * ## Styles
  */
.options {
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    height: $spacing * 9;
    justify-content: center;
    overflow: hidden;
    position: relative;
    text-indent: -999rem;
    width: $spacing * 9;

    &--open {

        &::before {
            transform: translateX(8px);
        }

        &::after {
            transform: translateX(-8px);
        }
    }

    &::before, &::after {
        @extend %pseudo-element;
        top: 9px;

    }

    &__body {
        background-color: $sonic-gray-1000;
        border: 0;
        height: calc($spacing);
        transition: opacity .3s ease-in;
        width: $spacing;
        border-radius: 100%;

        @include darkMode() {
            background-color: $white;
        }

        &::before, &::after {
            @extend %pseudo-element;
            bottom: 9px;
        }

        .options--open & {
            &::before {
                transform: translateX(8px);
            }
    
            &::after {
                transform: translateX(-8px);
            }
        }
    }
}