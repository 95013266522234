/**
 * # After Download Instructions
 */

/**
 * ## Imports
 */
 @import 'src/common/styles/variables.scss';
 @import 'src/common/styles/utilities.scss';
 
 /**
  * ## Styles
  */
  .tooltip {
    font-size: 1.15em;
    font-weight: 400;
    border-radius: 2em;
    display: flex;
    justify-content: center;
    color: $white;
    text-align: center;
    padding: .5em 1em;
    box-sizing: border-box;
    background: #1159AB;
    border: 2px solid $white;
    box-shadow: 0 2px 2px #0003, 0 3px 5px #0000001f, 0 6px 12px #0000000f;
    position: absolute;
    z-index: 200;

    &::after, &::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border: 8px solid transparent;
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        border-width: 16.5px;
    }

    &::after {
        left: calc(100% - 6px);
        border-left-color: #1159AB;
    }

    &::before {
        left: calc(100% - 6px);
        border-width: 18.5px;
        border: 17px solid transparent;
        border-left-color: $white;
    }

    &__bounce-top {
      flex-direction: column;
      right: 0;
      top: 20px;
      border-radius: 1em;
      animation: bounce-top 2s infinite;

      @include maxHd {
        right: 2.5%;
      }

      &::after, &::before {
        top: 100%;
        left: 84%;
        transform: translateY(0);
        transform: translateX(-84%);
      }

      &::after {
        top: calc(0% - 32px);
        border-left-color: transparent;
        border-bottom-color: #1159AB;
      }

      &::before {
        top: calc(0% - 33px);
        border-left-color: transparent;
      }
    }

    &__bounce-bottom {
      right: 40%;
      bottom: 20%;
      border-radius: 1em;
      animation: bounce-top 2s infinite;

      &::after, &::before {
        top: 100%;
        left: 50%;
        transform: translateY(0);
        transform: translateX(-50%);
      }

      &::after {
        top: calc(0% - 32px);
        border-left-color: transparent;
        border-bottom-color: #1159AB;
      }

      &::before {
        top: calc(0% - 33px);
        border-left-color: transparent;
      }

      @include maxHd {
        right: 80%;
        bottom: 48%;
        animation: bounce-right 2s infinite;

        &::after, &::before {
          content: "";
          display: block;
          width: 0;
          height: 0;
          border: 8px solid transparent;
          position: absolute;
          top: 50%;
          left: 100%;
          transform: translateY(-50%);
          border-width: 16.5px;
        }
    
        &::after {
            left: calc(100% - 6px);
            border-left-color: #1159AB;
        }
    
        &::before {
            left: calc(100% - 6px);
            border-width: 18.5px;
            border: 17px solid transparent;
            border-left-color: $white;
        }
      }
    }
  }

  .bounce-right {
    left: auto;
    bottom: auto;
    right: 480px !important;
    top: 15px;
    animation: bounce-right 2s infinite;

    @include maxHd {
      right: 620px !important;
    }
  }

  .text {
    margin: 0;
    padding: 0;
  }

  .icon {
    margin-bottom: -4px;
  }

  @keyframes bounce-right {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateX(-30px);
    }
    60% {
      transform: translateX(-15px);
    }
  }

  @keyframes bounce-top {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(20px);
    }
    40% {
      transform: translateY(40px);
    }
    60% {
      transform: translateY(25px);
    }
  }