/**
 * # Privacy Summary List
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Styles
 */
.privacy-summary {
    position: relative;
    z-index: 4;

    @include minSmMd {
        display: flex;
        gap: $spacing * 8;
    }

    @include maxSm {
        margin-top: $spacing * 7;
    }

    :global(.icon) {
        height: 180px;
        max-width: 100%;
        width: auto;
    }
}

.list-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-bottom: $spacing * 12;
    text-align: center;
    width: 100%;
    max-width: 230px;

    @include minSmMd {
        padding: 0 ($spacing * 8);
        width: calc(100% / 3);
    }

    :global(.icon) {
        width: auto;

        @include minSmMd {
            height: 152px;
        }
    }

    &:nth-of-type(1) {
        :global(.icon) {
            @include minSmMd {
                margin-top: 24px;
            }
        }
    }

    &:nth-of-type(3) {

        :global(.icon) {
            height: 220px;

            @include minSmMd {
                height: 200px;
            }
        }
    }
}

.paragraph {
    text-wrap: pretty;

    @include darkMode {
        color: $white;
    }

    @include minSm {
        max-width: 70%;
    }

    @include minSmMd {
        max-width: unset;
    }
}

.illustration-frame {
    margin-bottom: $spacing * 3;
    width: 100%;

    .list-item:nth-of-type(2) & {
        @include minSmMd {
            margin-bottom: $spacing * 6;
        }
    }

    .list-item:nth-of-type(3) & {
        @include minSmMd {
            margin-bottom: 0;
        }
    }
}