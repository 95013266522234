/**
 * # Feature Teaser
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Styles
 */
.wrapper {
    text-align: center;

    &:global(.centerwrapper) {
        margin-top: 0;
    }
}

.headline {
    font-family: $sonicFontFamilySemiBold;
    font-size: 32px;
    line-height: 48px;
    font-weight: 600;
    color: $sonic-gray-1000;
    @include center-x;
    margin: 0;
    text-wrap: balance;

    @include minSm {
       font-size: 48px;
       line-height: 72px;
       text-wrap: balance;
    }

    @include minSmMd {
        max-width: 50%;
    }
}

.teaser-list {
    max-width: 1200px;
    width: 100%;
    margin-top: 42px;

    @include minSm {
        margin-top: 64px;
    }
}