/**
 * # Contact Teaser
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Styles
 */
.wrapper {
    padding-bottom: $spacing * 20;
    padding-top: $spacing * 20;
    text-align: center;
    background-color: $white;
}

.headline {
    @extend %headline-primary-sonic;
    text-wrap: balance;
}

.copytext {
    margin-bottom: $spacing * 10;
}