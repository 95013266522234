/**
 * # Advantages List
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Variables
 */
$sonic-visual-overlap: 44px;

/**
 * ## Styles
 */
.advantages-screen-wrapper {
    position: relative;
    z-index: 2;

    &--sonic {
        z-index: 1;

        // &::before {
        //     @include minSmMd {
        //         content: '';
        //         display: block;
        //         background: url(../../../common/images/sonic_browser/cta-teaser-ornament-c-big.svg) left -260px no-repeat;
        //         height: 460px;
        //         left: 0;
        //         position: absolute;
        //         top: -320px;
        //         width: 300px;
        //         z-index: 1;
        //     }
        // }
    }
}

.advantages-screen {
    position: relative;
    text-align: center;
    z-index: 2;

    @include minSmMd {
        padding-top: 61px;
    }

    @include minMd {
        padding-top: 0;
    }

    &--sonic {
        margin-top: 80px;

        @include minSmMd {
            margin-top: 62px;
        }

        :global(.centerwrapper) {
            padding: 0;
            width: 100%;
        }
    }

    &__body {
        position: relative;
        z-index: 2;
    }

    &__headline {
        font-family: "Poppins", Helvetica, Arial, sans-serif;
        font-size: 26px;
        line-height: 31px;
        margin: 0 auto;
        opacity: 0;
        @include cssAnimation(fadeInUp, .3s, 1.25s);

        @include maxSmMd {
            margin-inline: auto;
            max-inline-size: 25ch;
            text-wrap: balance;
        }

        .advantages-screen--sonic & {
            color: $sonic-gray-1000;
            font-family: $sonicFontFamilySemiBold;
            font-weight: 600;
            font-size: 32px;
            line-height: 48px;
            text-align: center;
            max-width: 936.87px;
            margin-bottom: 42px;

            @include minSmMd {
                font-size: 48px;
                line-height: 72px;
                margin-bottom: 64px;
            }
        }
    }

    &__headline-sub {
        opacity: 0;
        @include cssAnimation(fadeInUp, .4s, 1.25s);
    }

    &__list {
        align-items: center;
        display: flex;
        flex-direction: column;
        gap: $spacing * 6;
        margin: 54px auto 70px;
        max-width: 910px;

        @include minSmMd {
            flex-direction: row;
            gap: 2px;
            justify-content: space-between;
            margin-top: 14px;
            max-width: 940px;
        }

        .advantages-screen--sonic & {
            gap: 32px;
            margin-bottom: 42px;

            @include minSmMd {
                margin: 0 0 2px;
                max-width: 1068px;
                margin-bottom: 64px;
                @include center-x;
            }
        }
    }

    &__list-item {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        max-height: 300px;
        max-width: 240px;
        min-height: 260px;

        .advantages-screen--sonic & {
            justify-content: flex-start;
            max-height: unset;
            min-height: unset;
            max-width: 334px;

            @include minSmMd {
            }
        }
    }

    &__image-wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        min-height: 100px;
        opacity: 0;
        @include cssAnimation(fadeInUp, .3s, 1s);

        @include minSmMd {
            min-height: 220px;
        }

        .advantages-screen--sonic & {
            justify-content: flex-start;
            min-height: unset;
            @include minSmMd {
                min-height: unset;
            }
        }
    }

    &__image {
        margin-bottom: $spacing * 3;
        width: auto;

        .advantages-screen__list-item:nth-of-type(3n + 1) & {
            margin-left: -6px;

            @include minSmMd {
                margin: -6px 0 0 3px;
            }

            .advantages-screen--sonic & {
                height: auto;
                margin: 0;
            }
        }

        .advantages-screen__list-item:nth-of-type(3n + 2) & {
            margin-right: -34px;

            @include minSmMd {
                margin-top: -3px;
            }

            .advantages-screen--sonic & {
                height: auto;
                margin: 0;
            }
        }

        .advantages-screen__list-item:nth-of-type(3n + 3) & {
            @include minSmMd {
                margin: 0 0 0 -10px;
            }

            .advantages-screen--sonic & {
                height: auto;
                margin: 0;
            }
        }
    }

    &__list-text {
        font-family: "Poppins", Helvetica, Arial, sans-serif;
        margin-top: 0;
        opacity: 0;
        @include cssAnimation(fadeInUp, .55s, 1.25s);

        :global(.advantage-subheadline) {
            font-family: 'Poppins Semi-Bold', Helvetica, Arial;

            .advantages-screen--sonic & {
                font-size: 20px;
                font-weight: 600;
                text-transform: capitalize;
                margin-top: 24px;
                margin-bottom: 16px;
                text-align: left;

                @include minSmMd {
                    font-size: 32px;
                    line-height: 48px;
                }
            }
        }

        :global(.advantage-paragraph) {
            margin-bottom: 0;

            .advantages-screen--sonic & {
                font-family: $sonicFontFamily;
                font-size: 16px;
                line-height: 27px;
                font-weight: 400;
                color: rgba($color: $sonic-gray-1000, $alpha: 0.7);
                text-align: left;
                margin: 0;
            }
        }

        /**
         * - Element selector not ideal but it comes
         * via translation string:
         */
        strong {
            font-family: "Poppins Semi Bold", Helvetica, Arial, sans-serif;
        }
    }

    &__closure {
        @extend %cta-paragraph;

        .advantages-screen--sonic & {
            font-size: 18px;
            line-height: 27px;
            margin-bottom: $spacing * 10;

            @include minSmMd {
                font-size: 20px;
                line-height: 30px;
                margin-bottom: $spacing * 5;
            }
        }
    }

    /**
     * - Component specific button styles:
     * 🚨 Todo: solve this via a `classList` approach! This won't work with CSS modules.
     */
    .button-cta {
        margin-bottom: $spacing * 15;
        opacity: 0;
        @include cssAnimation(fadeInUp, .7s, 1.25s);

        @include minSmMd {
            margin-bottom: 95px;
        }
    }

    .button-cta__body {
        @include minSmMd {
            font-size: 30px;
        }
    }

    /**
     * ### Ornament
     */
    &__ornament-wrapper {
        @include center-x(absolute);
        /* - This value needs to get adjusted by hand, once
         * the page's height would change:
         */
        height: 992px;
        overflow: hidden;
        pointer-events: none;
        text-align: center;
        top: -130px;
        width: 100%;
        z-index: 1;

        @include minSmMd {
            top: -200px;
        }

        @include minMd {
            top: -260px;
        }

        &::after {
            content: "";
            display: block;
            background: url(../../images/homepage_rebrand/advantage-ornament.svg) center 0 no-repeat;
            background-size: 2234px auto;
            width: 2234px;
            height: 1050px;
            transform-origin: center center;
            transform:
                rotate(5.7deg)
                translateX(-900px)
                translateY(74px);

            @include minSmMd {
                left: 50%;
                transform:
                    rotate(0deg)
                    translateX(-50%)
                    translateY(114px);
                position: relative;
            }
        }
    }
}