/**
 * # CTA Teaser
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.cta-teaser-wrapper {
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 2;

    @include minMd {
        max-height: 660px;
    }

    &.cta-teaser-sonic,
    &.cta-teaser-adblock {
        overflow: visible;
        max-height: unset;
    }

    &.cta-teaser-adblock {
        @include maxSm {
            padding-left: 16px;
            padding-right: 16px;
        }
    }
}

.cta-teaser-test {
    padding-bottom: 10px !important;
}

.cta-teaser {
    padding-top: ($spacing * 13);

    @include minSmMd {
        display: flex;
        gap: $spacing * 4;
        padding-left: 195px;
        position: relative;
    }

    .cta-teaser-sonic &,
    .cta-teaser-adblock & {
        padding-top: ($spacing * 8);
        text-align: center;
        text-wrap: balance;

        @include minSmMd {
            padding: 0;
            text-align: left;
        }
    }

    .cta-teaser-adblock & {
        padding-top: 0;
    }

    // .cta-teaser-sonic--overlap & {
    //     @include minSmMd {
    //         margin-bottom: -370px;
    //     }
    // }

    /**
     * ### Sonic Client:
     */
    .cta-teaser-sonic &,
    .cta-teaser-adblock & {
        text-align: center;

        @include minSmMd {
            display: block;
        }
    }

    &__left {
        position: relative;
        z-index: 2;

        @include minSmMd {
            flex-shrink: 2;
            max-width: $spacing * 129;
            min-height: 660px;
            padding-top: $spacing * 18;
            padding-bottom: $spacing * 10;
            width: 50%;
        }

        .cta-teaser-tempest & {
            text-align: center;
        }

        .cta-teaser-tempest & {
            @include minSmMd {
                text-align: left;
            }
        }

        .cta-teaser-sonic &,
        .cta-teaser-adblock & {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            opacity: 0;
            @include cssAnimation(fadeIn, .25s, 3s);

            @include maxSmMd {
                margin-bottom: $spacing * 8;
            }

            @include minSm {
                max-width: unset;
                min-height: unset;
                padding-top: $spacing * 5;
                text-align: center;
                @include center-x;
            }

            @include minSmMd {
                width: 80%;
            }
        }
    }

    &__visual {
        position: relative;

        @include minSmMd {
            width: 50%;
        }
    }

    &__visual-source {
        max-width: 100%;
    }

    &__visual-image {
        margin-left: -1%;
        width: 106%;
        opacity: 0;
        position: relative;
        z-index: 2;
        @include cssAnimation(slideHalfRightToLeft, .25s, 1.5s);

        @include minSm {
            margin-left: 0;
            width: 100%;
        }

        @include minSmMd {
            left: 0;
            height: auto;
            min-width: 930px;
            position: absolute;
        }

        .cta-teaser-sonic &,
        .cta-teaser-adblock & {
            margin-left: 3%;
            width: 100%;
            
            @include minSmMd {
                position: static;
                max-height: 790px;
                min-width: auto;
                width: auto;
                margin-right: -165px;
            }

            @include minSmMdMaxMd {
                width: 100%;
            }
        }

        .cta-teaser-adblock & {
            margin-left: 0;
            
            @include minSmMd {
                max-height: 810.6px;
            }

            @include minSmMaxSmMd {
                width: 100%;
            }
        
            @include minSmMdMaxMd {
                width: 100%;
            }
        }
    }
}

.headline-main {
    .cta-teaser-tempest & {
        @extend %headline-main-tempest;
    }

    .cta-teaser-sonic &,
    .cta-teaser-adblock & {
        @extend %headline-primary-sonic;

        @include minSm {
            max-width: 70%;
        }

        @include minSmMd {
            max-width: 60%;
        }
    }
}

:global(.headline-sub) {
    @extend %headline-sub-tempest;

    .cta-teaser__left & {
        margin-bottom: $spacing * 4;
        text-align: center;

        @include minSmMd {
            margin: $spacing 0 ($spacing * 8);
            text-align: left;
        }
    }
}

.button-wrapper {
    .cta-teaser-sonic & {
        @include minSmMd {
            margin-top: $spacing * 5;
        }
    }
}