/**
 * # 404 Not Found
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Styles
 */
.not-found {
    text-align: center;
    text-wrap: balance;
    max-width: 700px;
}

.headline {
    @extend %headline-primary-sonic;
}

.sub-headline {
    @extend %headline-tertiary-sonic;
}

.description {
    @extend %headline-quaternary-sonic;
}

.link {
    @include darkMode {
        color: $sonic-blue-400;
    }
}