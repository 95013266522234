/**
 * # Mobile Menu
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Styles
 */
.mobile-menu {
    max-width: $screen-min-md-lg;
    width: 100%;
    z-index: 10;
    @include center-x(relative);

    &--hidden {
        @extend %sr-only;
    }

    &--adblock {
        max-width: 100%;
    }
}

.navlist {
    background-color: $white;
    border-radius: $border-radius-l;
    box-shadow: 0 1px 5px 2px rgba($black, .25);
    font-size: 16px;
    right: 0;
    max-width: 400px;
    min-width: 300px;
    padding: ($spacing * 3) $spacing ($spacing * 4);
    position: fixed;
    top: $spacing * 3;
    width: calc(100% - ($spacing * 12));
    z-index: 1;

    .mobile-menu--adblock & {
        width: 100vw;
        max-width: 100vw;
        box-shadow: 0 20px 32.8px -29px rgba(163, 163, 163, 0.2509803922);
        opacity: 97%;
        font-family: $adblockFontFamily;
        font-size: 18px;
        line-height: 25.9px;
        font-weight: 400;
        text-align: right;
        color: $adblock-text;
        right: -7.2%;
        padding-right: 16px;
        border-radius: 24px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        @include minXsm {
            right: -6%;
        }
    }
}

.list-item {
    margin: 0 ($spacing * 7);
    min-width: calc(100% - ($spacing * 14));

    &:nth-of-type(n + 2) {
        border-top: 1px solid $gray-600;
    }

    .mobile-menu--adblock & {
        margin: 0;
        border-top: unset;
    }
}

.list-link {
    color: $gray-800;
    display: inline-block;
    padding: ($spacing * 6) ($spacing * 4) ($spacing * 5);
    text-decoration: none;
    width: 100%;

    &--disabled {
        color: $gray-300;
    }

    .mobile-menu--adblock & {
        color: $adblock-text;
        padding: 0;
        padding-bottom: 16px;

    }
}