/**
 * # Page Header
 */
/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Styles
 */
.pageheader {
    background: transparent;
    border-bottom: 1px solid transparent;
    box-shadow: none;
    padding: ($spacing * 4);
    position: fixed;
    transition: background-color 0.3s ease-in, border-color 0.3s ease-in;
    width: 100%;
    z-index: 6;

    &--scrolled {
        background-color: rgba($white, .95);
        border-color: $gray-200;
        box-shadow: 0 0 4px 0 rgba($gray-800, 0.15);
        backdrop-filter: blur(4px);

        @include darkMode {
            background-color: rgba($marketing-blue-gray-900, .96);
            border-color: rgba($white, .04);
        }
    }

    &--adblock {
        width: 100%;
        margin: 0 auto;
        padding: 0 3.16%;
        padding-top: 40px;
        padding-bottom: 40px;
    }

    &__body {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding-bottom: $spacing;

        &:global(.centerwrapper) {
            padding: 0;
            width: 100%;
        }

        .pageheader--adblock & {
            padding-bottom: 0;
            max-width: 1799px;
        }
    }

    :global(.app--sonic) & {
        padding: 35px 61px;

        @include maxSmMd {
            padding: 16px;
        }
    }

    :global(.app--sonic) & :global(.centerwrapper) {
        max-width: 1920px;
    }
}

/**
 * - Preserving space for fixed pageheader.
 */
:global(.follows-pageheader) {
    padding-top: 120px;

    :global(.app--adblock) & {
        padding-top: 124px;

        @include minSm {
            padding-top: 226.13px;
        }
    }
}

.nav {
    line-height: 1;
}

.list {
    display: flex;
    gap: $spacing * 6;

    @include minMd {
        gap: $spacing * 10;
    }
}

.list-item {
    align-items: center;
    display: flex;
}

.link {
    color: $sonic-gray-1000;
    text-decoration: none;
    white-space: nowrap;

    :global(.app--sonic) & {
        font-weight: 500;
        font-size: 18px;
        line-height: 58px;
        color: rgba($sonic-gray-1000, .7);
    }

    .pageheader--adblock & {
        font-weight: 400;
        font-size: 18px;
        line-height: 58px;
        color: $adblock-text;
    }

    @include darkMode {
        color: $white;
    }

    /** - Just a test to toggle the class in JSX output.
     * This component isn't used in the `tempest` client.
     */
    .pageheader--sonic & {
        font-size: 14px;

        @include minSmMd {
            font-size: 18px;
        }
    }
}

.active-point {
    color: $sonic-gray-1000;
    font-family: "Poppins Semi Bold", Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 14px;

    @include darkMode {
        color: $white;
    }

    @include minSmMd {
        font-size: 18px;
    }
}

.mobile-menu-wrapper {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    height: $spacing * 8;
    justify-content: flex-end;
    position: relative;
}

.custom-header {
    @include darkMode {
        color: $white;
    }
}