@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

.arrow-steps {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    width: 100%;
}

.step {
    height: 41px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    min-width: 200px;
    margin: 0 4px 16px;
    padding: 10px 10px 10px 30px;
    float: left;
    background-color: #d9e3f7;
    color: #666;
    position: relative;

    &__current, &__past {
        background-color: #2B4190;
        color: $white;

        &[data-step="1"] {
            background-color: $sonic-blue-400;
            &::after {
                border-left: 17px solid $sonic-blue-400 !important;
            }
        }
    
        &[data-step="2"] {
            background-color: #2B62C3;
            &::after {
                border-left: 17px solid #2B62C3 !important;
            }
        }
    
        &[data-step="3"] {
            background-color: $sonic-blue-700;
        }
    }

    &:first-child {
        border-top-left-radius: 100px;
        border-bottom-left-radius: 100px;
    }

    &:last-child {
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;

    }

    &:last-child::after, &:first-child::before {
        border: none;
    }

    &::after, &::before {
        content: " ";
        position: absolute;
        top: 0;
        right: -16px;
        width: 0;
        height: 0;
        border-top: 20px solid transparent;
        border-bottom: 21px solid transparent;
        border-left: 17px solid #d9e3f7;
        z-index: 2;
        transition: border-color .2s ease;
    }

    &::before {
        right: auto;
        left: -1px;
        border-color: transparent;
        border-left: 17px solid white;
        z-index: 0;
    }

    @include darkMode() {
        &::before {
            border-left: 17px solid #0B0B1B;
        }
    }
}

.check-icon {
    color: $white;
    margin-right: 5px;
}
