/**
 * # Privacy Feature List
 */

/**
 * ## Import
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Styles
 */
.list {
    background-color: $white;
    border-radius: $border-radius-xl;
    display: flex;
    flex-wrap: wrap;
    gap: $spacing * 10;
    justify-content: center;
    margin: 0 auto ($spacing * 10);

    @include minSm {
        border-radius: $border-radius-xxl;
    }

    @include darkMode {
        background-color: $marketing-blue-black-500;
    }

    &:global(.centerwrapper) {
        padding: ($spacing * 12) ($spacing * 4) ($spacing * 10);
        margin: 0 auto ($spacing * 10);

        @include minSm {
            margin-bottom: ($spacing * 10);
            padding-top: ($spacing * 21);
        }
    }
}

.list-item {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    max-width: $spacing * 120;
    width: 100%;

    @include minSm {
        padding: 0 ($spacing * 6);
    }

    @include minSmMd {
        margin-bottom: $spacing * 6;
        width: calc(100% / 2 - $spacing * 16);
    }
}

.icon-wrapper {
    align-items: center;
    background: $sonic-lavender-400;
    border-radius: $spacing * 6;
    display: flex;
    height: 120px;
    justify-content: center;
    width: 120px;

    :global(.icon) {
        height: 60px;
        width: 60px;
    }

    .list-item:nth-child(3) & {
        background-color: $sonic-lavender-500;
    }

    .list-item:nth-child(4) &,
    .list-item:nth-child(5) & {
        background-color: $sonic-lavender-600;
    }

    .list-item:nth-child(6) & {
        background-color: $sonic-lavender-700;
    }

    .list-item:nth-child(7) &,
    .list-item:nth-child(8) & {
        background-color: $sonic-lavender-800;
    }
}

.title {
    @extend %headline-exception-sonic-tertiary;
    text-align: center;
    width: 100%;
}

.description {
    @extend %description-label;
    text-align: center;
}

.text-body {
    margin: 0 auto ($spacing * 6);
    text-align: center;
}

.headline {
    @extend %headline-tertiary-sonic;
    text-wrap: balance;
    margin-bottom: 0;
    font-family: "Poppins Semi Bold";
}

.copytext {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
}