/**
 * # Customization Feature Teaser
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Variables
 */
$gap: $spacing * 4;

/**
 * ## Styles
 */
.wrapper {
    position: relative;
    z-index: 3;
}

.layout-list {
    @include minSm {
        display: flex;
        flex-wrap: wrap;
        gap: $gap;
        justify-content: space-between;
    }
}

.list-item {
    background-color: $white;
    border-radius: $border-radius-m;

    @include maxSm {
        margin-bottom: $spacing * 2;
    }

    @include minSm {
        width: calc(100% / 2 - #{calc($gap / 2)});
    }

    @include minSmMd {
        width: calc(100% / 3 - #{calc($gap * 2 / 3)});
    }
}

.icon-wrapper {
    align-items: center;
    background-color: $sonic-blue-100;
    border-radius: $border-radius-m $border-radius-m 0 0;
    display: flex;
    height: 261px;
    justify-content: center;
}

.visual-image {
    @include maxSm {
        /* compensating for `.centerwrapper`'s padding: */
        margin: -100px (-$spacing * 4) ($spacing * 4);
        max-width: calc(100% + ($spacing * 8));
    }

    @include minSm {
        margin-bottom: $spacing * 26;
        margin-top: -260px;
        max-width: 100%;
    }
}

.text-wrapper {
    padding: $spacing * 4;
    text-align: center;
}

.headline {
    @extend %headline-exception-sonic-tertiary;
    @include darkMode {
        color: $sonic-blue-gray-900;
    }
}

.copytext {
    @extend %description-label;
    text-wrap: pretty;
    @include darkMode {
        color: $sonic-blue-gray-900;
    }
}