/**
 * # Page Footer
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Styles
 */
.pagefooter {
    max-width: 100%;
    padding-bottom: $spacing * 4;
    position: relative;
    z-index: 3;

    @include minSm {
        padding-bottom: $spacing * 6;
    }

    &--sonic,
    &--adblock {
        color: $black;
        overflow: hidden;
        position: relative;
        z-index: 2;
        background-color: $aquaHaze;

        @include minSmMd {
            width: 100%;
            padding-top: 80px;
            margin: 0;
        }

        @include maxMd {
            padding-left: 20px;
            padding-right: 20px;
        }
    }

    &--adblock {
        font-family: $adblockFontFamily;
        color: $adblock-text;
        background-color: $adblock-footer;
    }

    &__boilerplate-body {
        column-gap: ($spacing *  4);
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: repeat(2, 1fr);
        position: relative;
        row-gap: 54px;
        width: 100%;

        @include minSmMd {
            grid-template-columns: repeat(3, 1fr);
        }

        .pagefooter--sonic &,
        .pagefooter--adblock & {
            width: 100%;
            max-width: 1200px;
            padding-left: 0;
            padding-right: 0;

            @include maxSmMd {
                row-gap: 39px;
            }

            @include minSmMd {
                grid-template-columns: 1fr 3fr 1fr;
            }
            // &::before {
            //     bottom: -150px;
            //     content: '';
            //     display: block;
            //     background: url(../../images/sonic_browser/cta-teaser-ornament-b-big.svg) 0 0 no-repeat;
            //     height: 390px;
            //     left: auto;
            //     position: absolute;
            //     right: -140px;
            //     top: auto;
            //     transform: rotate(180deg);
            //     width: 473px;
            //     z-index: 1;

            //     @include minSm {
            //         right: -100px;
            //     }

            //     @include minSmMd {
            //         bottom: -24px;
            //         right: -250px;
            //     }
            // }
        }

        .pagefooter--adblock & {
            @include maxSmMd {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                row-gap: 8px;
            }

            @include minSmMd {
                grid-template-columns: 2fr 5fr 1fr;
            }
        }
    }

    &__boilerplate-imprint {
        grid-row-start: 1;
        text-align: center;
        pointer-events: all;
        list-style: none;

        @include maxSmMd {
            grid-column: 1 / span 2;
        }

        @include minSmMd {
            display: flex;
            gap: $spacing * 4;
            justify-content: center;
        }

        .pagefooter--sonic & {
            @include maxSmMd {
                align-self: center;
                grid-column: 1 / span 2;
                grid-row-start: 2;
                justify-self: flex-start;
                display: block;
            }
        }

        .pagefooter--adblock & {
            @include maxSmMd {
                display: flex;
                justify-content: center;
                max-width: 100%;
                flex-wrap: wrap;
            }
        }
    }

    &__boilerplate-item {
        margin: ($spacing * 2) 0;

        .pagefooter--sonic &,
        .pagefooter--adblock & {
            align-self: center;

            @include maxSmMd {
                display: inline-block;
                float: left;
                margin-right: 17px;
            }
        }

        .pagefooter--adblock & {
            @include maxSmMd {
                margin-right: 15px;
            }
        }
    }

    &__boilerplate-link {
        @extend %homepage-link-sober;

        .pagefooter--sonic &,
        .pagefooter--adblock & {
            color: $sonic-blue-gray-900;
            display: block;
            opacity: .5;
            text-wrap: nowrap;

            @include darkMode {
                color: rgba($white, .5);
            }

            &:hover {
                text-decoration: underline;
            }
        }

        .pagefooter--adblock & {
            color: $adblock-link;
        }
    }

    &__boilerplate-logo {
        .pagefooter--sonic & {
            path {
                @include darkMode {
                    fill: $white;
                }
            }
        }
    }

    &__imprint {
        color: rgba($white, .5);
        font-size: 14px;
        grid-row-start: 2;
        text-align: right;
        white-space: nowrap;

        @include maxSmMd {
            align-self: center;
        }

        @include minSmMd {
            grid-row-start: 1;
        }

        .pagefooter--sonic &,
        .pagefooter--adblock & {
            color: $sonic-blue-gray-900;
            opacity: .5;
            position: relative;
            z-index: 2;

            @include minSmMd {
                align-items: center;
                display: flex;
                justify-content: flex-end;
            }

            @include maxSmMd {
                align-self: center;
                grid-column: 1 / span 2;
                grid-row-start: 3;
                justify-self: flex-start;
            }

            @include darkMode {
                color: rgba($white, .5);
            }
        }

        .pagefooter--adblock & {
            color: $adblock-link;

            // @include minSmMaxSmMd {
            //     grid-row-start: 2;
            //     margin: 0 auto;
            //     grid-column-start: 2;
            // }
        
            // @include minSmMdMaxMd {
            //     grid-row-start: 2;
            //     margin: 0 auto;
            //     grid-column-start: 2;
            // }
        }
    }
}

.footer-menu {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-family: $sonicFontFamily;
    margin-bottom: 48px;

    @include maxSmMd {
        flex-direction: column;
        margin-top: 40px;
        margin-bottom: 40px;
    }

    &--adblock {
        font-family: $adblockFontFamily;

        @include maxSmMd {
            margin-top: 24px;
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        @include maxSmMd {
            margin-bottom: 32px;
        }

        & a {
            text-decoration: none;
        }

        &-title {
            font-family: $sonicFontFamilySemiBold;
            font-size: 18px;
            line-height: 18px;
            font-weight: 600;
            letter-spacing: 0.32px;
            color: $black;
            margin: 0;
            margin-bottom: 34px;


            .footer-menu--adblock & {
                font-family: $adblockFontFamily;
                font-weight: 700;
                color: $black;
            }

            @include maxSmMd {
                width: 100%;
                margin-bottom: 0;

                .footer-menu--sonic & {
                    cursor: pointer;
                }

                .footer-menu--sonic &::after {
                    content: '\f107';
                    font-family: 'FontAwesome';
                    font-size: 14px;
                    float: right;
                    transition: all .2s ease-in-out;

                    .footer-menu__column-active & {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    &__column-items {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        transition: all .3s ease-in-out;

        @include maxSmMd {
            .footer-menu--sonic & {
                max-height: 0;
                overflow: hidden;

                .footer-menu__column-active & {
                    max-height: 90px;
                    margin-top: 24px;
                }
            }

            .footer-menu__column & {
                margin-top: 24px;
            }
        }
    }

    &__column-item {
        margin: 0;
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 0.32px;
        color: $black;
        text-decoration: none;
    }
}

.social {
    @include maxSmMd {

        margin-bottom: 0;

        & .footer-menu__column-title {
            cursor: auto;

            &::after {
                display: none;
            }
        }

        & .footer-menu__column-items {
            max-height: max-content;
            margin-top: 24px;
        }

        & .footer-menu__column-item {
            &--desktop {
                display: none;
            }
        }
    }
}

.social-links {
    width: 24px;
    height: 24px;
    color: $black;
    margin-right: 20px;
    font-size: 17px;
    z-index: 3;
    position: relative;

    &--mobile {
        display: none;

        @include maxSmMd {
            display: inline;
        }
    }
}