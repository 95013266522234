/**
 * # IllustrationWithListTeaser
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Styles
 */
.wrapper {
    @include minSm {
        margin-bottom: $spacing * 20;
    }
}

.text-body {
    text-align: center;
}

.headline {
    @extend %headline-exception-sonic-secondary;
}

.subline {
    @extend %headline-exception-sonic-quaternary;

    @include minSm {
        margin: 0 auto ($spacing * 18);
        max-width: 70%;
        text-wrap: balance;
    }

    @include maxSmMd {
        margin-bottom: 0;
    }
}

.layout-wrapper {
    display: flex;
    flex-direction: column;

    @include minSmMd {
        flex-direction: row;
        gap: $spacing * 30;
    }
}

.illustration {
    margin-top: $spacing * 10;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    order: 1;

    @include maxSmMd {
        margin-top: 37px;
        margin-bottom: 55px;
        height: auto;
    }

    @include minSm {
        max-width: fit-content;
    }

    @include minSmMd {
        max-width: 60%;
        order: 2;
    }
}

.sync-list {
    order: 2;

    @include minSmMd {
        margin-bottom: $spacing * 12;
        max-width: 40%;
        order: 1;
    }

    &__item {
        margin-bottom: $spacing * 12;
    }

    &__headline {
        @extend %headline-exception-sonic-quintary;
        margin: 0 0 ($spacing * 2);

        @include darkMode {
            color: $white;
        }
    }

    &__copytext {
        margin-top: 0;
        opacity: .7;
        text-wrap: balance;
    }
}