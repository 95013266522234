/**
 * # Experience Feature component
 */
/**
 * ## Imports
 */
 @import 'src/common/styles/variables.scss';
 @import 'src/common/styles/utilities.scss';

 /**
 * Styles
 */
 .wrapper {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;

    @include maxMd {
        padding-left: 16px;
        padding-right: 16px;
    }
 }

 .title {
    font-family: $adblockFontFamily;
    font-size: 24px;
    line-height: 34.54px;
    text-align: left;
    font-weight: 700;
    color: $black;
    margin: 0;
    margin-bottom: 24px;

    @include minSm {
        font-size: 48px;
        line-height: 69.07px;
        text-align: center;
        margin-bottom: 64px;
    }
 }

 .cards {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 48px;

    @include maxSm {
        gap: 40px;
    }

    &--row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        @include maxSm {
            flex-direction: column;
            gap: 40px;
        }
    }
 }

 .card {
    width: 100%;
    max-width: 580px;
    font-family: $adblockFontFamily;

    @include minSmMaxSmMd {
        width: 49%;
    }

    @include minSmMdMaxMd {
        width: 49%;
    }

    &--image {
        width: 100%;
        max-height: 490px;
    }

    &--title {
        font-size: 32px;
        line-height: 32px;
        font-weight: 700;
        color: $black;
        margin-top: 40px;
        margin-bottom: 16px;
        text-wrap: pretty;
        padding-left: 20px;
        padding-right: 20px;

        @include maxSm {
            font-size: 20px;
            line-height: 20px;
            padding-left: 0;
            padding-right: 0;
            margin-top: 24px;
        }
    }

    &--text {
        font-size: 16px;
        line-height: 27px;
        font-weight: 400;
        color: $adblock-text;
        margin: 0;
        padding-left: 20px;
        padding-right: 20px;
        color: $adblock-text;
        opacity: 70%;

        @include maxSm {
            padding-left: 0;
            padding-right: 0;
        }

        .card:first-child &,
        .card:last-child & {
            max-width: 535px;
        }
    }
 }

 .button {
    display: block;
    margin: 0 auto;
    margin-top: 64px;
    padding: 0 40px;

    @include maxSm {
        margin: 0;
        margin-top: 24px;
        padding: 0 16px;
        height: 60px;
        min-width: 178px;
      }

    & span {
        font-size: 19.85px;
        line-height: 41.96px;
    }
 }