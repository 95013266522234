/**
 * # FAQ
 */

/**
 * ## Imports
 */
 @import 'src/common/styles/variables.scss';
 @import 'src/common/styles/utilities.scss';
 
 /**
  * ## Styles
  */
 .wrapper {
     margin-bottom: 111px;
     padding-top: 100px;
     text-align: center;
     display: block;
     min-height: 40%;
     z-index: 5;

     @include maxSm {
      padding-top: 60px;
     }
 }
 
 .headline {
     font-family: $sonicFontFamilySemiBold;
     font-size: 48px;
     line-height: 72px;
     text-align: center;
     color: $sonic-gray-1000;
     margin-top: 0;

      @include maxSm {
         margin-bottom: 24px;
      }
 }

 .list {
    list-style: disc;
    margin-left: ($spacing * 10);
    margin-top: $spacing * 5;
    margin-bottom: $spacing * 5;
    
    &__no-gap {
        margin-top: 0;
        margin-bottom: 0;
    }

    &__numeric {
        list-style: auto;

        & li {
            margin-bottom: ($spacing * 2);
        }

        & li:first-child {
            margin-left: -2px;
        }
    }
 }

 .bold {
    font-family: 'Poppins Semi Bold', Arial, Helvetica, sans-serif;
 }

 .mb-20 {
    margin-bottom: $spacing * 5;
 }

 .icon {
    display: inline-block;
    margin-bottom: -7px;
 }

 .with-icon-text {
    line-height: 33px;
 }

 .huge-img {
    max-width: 900px;
    width: 100%;
 }

 .mid-img {
    max-width: 500px;
    width: 100%;
 }

//  Adblock
.adblock {
   max-width: 664px !important;
   
   & .headline {
      font-family: $adblockFontFamily;
      font-size: 48px;
      line-height: 69.07px;
      font-weight: 700;
      color: $black;

      @include maxSm {
         font-size: 24px;
         line-height: 34.54px;
      }
   }

   & .bold {
      font-family: $adblockFontFamily;
      font-weight: 700;
   }
}