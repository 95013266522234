/**
 * # Button Burger
 */
/**
 * ## Imports
 */
@import 'src/common/styles/variables';
@import 'src/common/styles/utilities';

/**
 * ## Placeholders
 */
%pseudo-element {
    background-color: $marketing-blue-black-400;
    content: "";
    display: block;
    height: calc($spacing / 2);
    position: absolute;
    transition: transform .3s ease-in;
    width: $spacing * 6;

    @include darkMode {
        background-color: $white;
    }
}

/**
 * ## Styles
 */
.burger {
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    flex-shrink: 0;
    height: $spacing * 9;
    justify-content: center;
    overflow: hidden;
    position: relative;
    text-indent: -999rem;
    width: $spacing * 9;

    .app-tempest & {
        margin-right: $spacing * 3;
        margin-top: $spacing * 3;
    }

    &--open {

        &::before {
            transform: translateY(8px) rotate(45deg);
        }

        &::after {
            transform: translateY(-8px) rotate(-45deg);
        }
    }

    &::before {
        @extend %pseudo-element;
        top: 9px;

    }

    &::after {
        @extend %pseudo-element;
        bottom: 9px;
    }

    &__body {
        background-color: $marketing-blue-black-400;
        border: 0;
        height: calc($spacing / 2);
        transition: opacity .3s ease-in;
        width: $spacing * 6;

        @include darkMode {
            background-color: $white;
        }

        .burger--open & {
            opacity: 0;
        }
    }
}