/**
 * # Customization Teaser
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Styles
 */
.wrapper {
    padding: 0 ($spacing * 4);
}

.headline {
    @extend %headline-exception-sonic;

    &--first {
        text-align: center;
        text-wrap: balance;
    }
}

.paragraph {
    text-align: center;
    text-wrap: balance;
    margin-bottom: $spacing * 8;
    @include center-x;

    @include minSm {
        margin-bottom: $spacing * 12;
        max-width: 70%;
    }
}

.list-item {
    background-color: $white;
    border-radius: $spacing * 9;
    margin-bottom: $spacing * 4;
    padding: $spacing * 4;

    @include darkMode {
        background-color: $marketing-blue-black-500;
    }
}

.list-section {
    @include minSm {
        display: flex;
        gap: $spacing * 2;
        justify-content: space-between;
    }
}

.illustration-wrapper {
    /* SVG files come with extra white space. Just a work around: */
    aspect-ratio: 1 / 0.68;
    display: block;
    overflow: hidden;
    width: 100%;

    @include maxSm {
        margin-bottom: $spacing * 6;
    }

    @include minSm {
        aspect-ratio: 1 / 0.63;
        min-width: 44%;
        width: calc(50% - $spacing);
    }

    @include minMd {
        width: 48%;
    }

    .list-item:nth-child(even) & {
        @include minSm {
            order: 2;
        }
    }

    .list-item:nth-child(odd) & {
        @include minSm {
            order: 1;
        }
    }

    /* SVG files come with extra white space. Just a work around: */
    .list-item:nth-of-type(2) & {
        aspect-ratio: 1 / 0.71;

        @include minSm {
            aspect-ratio: 1 / 0.66;
        }
    }
}

.illustration {
    width: 100%;
}

.list-text {
    @include minSm {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        order: 2;
        padding: 0 ($spacing * 6);
        width: calc(50% - $spacing);
    }

    @include minMd {
        padding: 0 ($spacing * 21);
    }

    .list-item:nth-child(even) & {
        @include minSm {
            order: 1;
        }
    }

    .list-item:nth-child(odd) & {
        @include minSm {
            order: 2;
        }
    }
}

.list-paragraph {
    color: rgba($marketing-gray-900, .7);
    margin-top: 0;

    @include darkMode {
        color: rgba($white, .7);
    }
}