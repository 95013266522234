/**
 * # Privacy Teaser
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Mixins
 */

/* - The parameter `$dark-mode-icon-color` is optional. */
@mixin icon-mask(
    $icon-src,
    $icon-color,
    $dark-mode-icon-color: $icon-color
) {
    background-color: $icon-color;
    display: inline-block;
    -webkit-mask-image: url($icon-src);
    mask-image: url($icon-src);
    overflow: hidden;
    text-indent: -999em;
    height: 24px;
    width: 24px;

    @include darkMode {
        background-color: $dark-mode-icon-color;
    }
}

/**
 * ## Styles
 */
.text-body {
    text-align: center;
}

.headline {
    @extend %headline-exception-sonic-secondary;
}

.subline {
    @extend %headline-exception-sonic-quaternary;

    @include minSm {
        margin: 0 auto ($spacing * 18);
        text-wrap: balance;
    }

    @include minMd {
        max-width: 70%;
    }
}

.teaser-list {
    @include minSmMd {
        display: flex;
        gap: $spacing * 4;
        margin-bottom: $spacing * 16;
    }

    &__item {
        background-color: $white;
        border-radius: $border-radius-xxl;
        margin-bottom: $spacing * 4;
        padding: $spacing * 4;

        @include darkMode {
            background-color: $marketing-blue-black-500;
        }

        @include minSm {
            padding: $spacing * 8;
        }

        @include minSmMd {
            display: flex;
            flex-direction: column;
            width: calc(100% / 3);
        }
    }
}

.teaser-image-wrapper {
    align-items: center;
    background: linear-gradient(75.38deg, #F7941D 24.68%, #F9ED32 82.43%);
    border-radius: 100%;
    display: flex;
    height: 79px;
    justify-content: center;
    margin-bottom: $spacing * 4;
    width: 79px;

    .teaser-list__item:nth-of-type(2) & {
        background: linear-gradient(185.95deg, #2B4190 17.15%, #5A9CFF 70.23%);
    }

    .teaser-list__item:nth-of-type(3) & {
        background: linear-gradient(261.02deg, #4B21C2 11.58%, #D885FF 95.24%);
    }
}

.teaser-icon {
    height: 40px;
    width: 40px;
}

.teaser-headline {
    @extend %headline-exception-sonic-quintary;
    margin: 0 0 ($spacing * 2);

    @include darkMode {
        color: $white;
    }

    @include minSmMd {
        text-wrap: balance;
        max-width: 100%;
    }

    .teaser-list__item:nth-of-type(2) & {
        @include minSmMd {
            max-width: 90%;
        }

        @include minMd {
            max-width: 76%;
        }
    }

    .teaser-list__item:nth-of-type(3) & {
        @include minSmMd {
            max-width: 80%;
        }
    }
}

.teaser-label {
    @extend %description-label;

    @include minSmMd {
        flex-grow: 2;
    }
}

.availability {
    &__list {
        display: flex;
        gap: $spacing;
    }

    &__icon {
        height: 20px;
        width: 20px;

        :global(.path) {
            @include darkMode {
                fill: $white;
            }
        }
    }

    &__desktop {
        @include icon-mask("../../images/sonic_browser/icons/monitor.svg", rgba($black, .6), rgba($white, .6));
    }

    &__smartphone {
        @include icon-mask("../../images/sonic_browser/icons/smartphone.svg", rgba($black, .6), rgba($white, .6));
    }
}

.button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 70px;
}

.continue-button {
    button {
        width: 270px;
        height: 80px;
        font-size: 20px;
        border-radius: 20px;
    }
}