/**
 * # After Download Instructions
 */

/**
 * ## Imports
 */
@import 'src/common/styles/variables.scss';
@import 'src/common/styles/utilities.scss';

/**
 * ## Styles
 */
$gap: $spacing * 8;

.list {
    @include minSmMd {
        display: flex;
        gap: $gap;
    }
}

.list-item {
    margin-bottom: $spacing * 16;
    text-align: center;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-start;
    // align-items: center;

    @include minSmMd {
        width: calc(100% / 3 - ($gap / 3));
    }
}

.image {
    height: auto;
    max-width: 100%;
    width: 300px;

    .adblock & {
        width: auto;
        height: 100%;
        max-height: 262px;
    }
}

.step {
    color: rgba($marketing-gray-900, .7);
    font-size: 12px;
    line-height: 27px;
    text-transform: uppercase;
}

.headline {
    @extend %headline-exception-sonic-quintary;
    margin: 0;
}

.copytext {
    font-size: 14px;
    color: rgba($marketing-gray-900, .7);
}

.arrow-steps-wrapper {
    margin-top: 20px;
}

.last-step-area {
    height: 40vh;
}